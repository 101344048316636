import apiClient from './client'

interface MuteThreadRequest {
    muted_thread: {
        reverie_id: number
    }
}

export const thread = {
    muteThread: (data: MuteThreadRequest) =>
        apiClient.post('muted_threads', data),
    unMuteThread: (reverie_id: number) =>
        apiClient.delete(`muted_threads/${reverie_id}`),
}
