// AppContext.js
import React, { useEffect, useMemo, useState } from 'react'

import apiClient from '../api/client'

export const AuthContext = React.createContext({
    user: {
        attributes: {
            username: '',
            email: '',
            name: '',
            mobile: '',
            bio: '',
            experience: '',
            currently_working: '',
            avatar: {
                original: '',
                thumbnail: '',
            },
            cover_image: {
                original: '',
            },
            image_style: {
                id: '',
            },
            social_links: {
                blog1: '',
                blog2: '',
                twitter: '',
                facebook: '',
                linkedin: '',
                spotify: '',
                instagram: '',
                discord: '',
                pinterest: '',
                youtube: '',
                tiktok: '',
                twitch: '',
                bsky: '',
                mastodon: '',
            },
            stream_token: '',
            reputation: 0,
            is_admin: false,
            receive_email_notifications: false,
            tags: [
                {
                    id: '',
                    name: '',
                },
            ],
            serendipity_ratio: null,
        },
        id: '',
        type: '',
    },
    token: '',
    refetchUserData: () => {},
    signout: () => {},
})

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<any>({})
    const [token, setToken] = useState('')

    useMemo(() => {
        const user = localStorage.getItem('user')
        const token = localStorage.getItem('token')
        // @ts-ignore
        setUser(JSON.parse(user))
        // @ts-ignore
        setToken(JSON.parse(token))
        if (user) {
            return JSON.parse(user)
        }
        return {}
    }, [])
    // check the user is authenticated or not and redirect to login page if not authenticated
    useEffect(() => {
        if (user && token) {
            apiClient.defaults.headers.common['Authorization'] =
                `Bearer ${localStorage.getItem('token')}`
        }
    }, [user, token])
    useEffect(() => {
        if (user && token) {
            refetchUserData()
        }
    }, [])
    const refetchUserData = () => {
        apiClient
            .get('/auth/profiles')
            .then(async (res: any) => {
                await localStorage.setItem(
                    'user',
                    JSON.stringify(res.data.data)
                )
                setUser(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const signout = () => {
        setUser({})
        setToken('')
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        apiClient.defaults.headers.common['Authorization'] = ''
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                token,
                refetchUserData,
                signout,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const UseAppContext = () => React.useContext(AuthContext)
