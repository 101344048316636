import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import Discover from 'modules/discover'

const Login = lazy(() => import('modules/login'))
const Interests = lazy(() => import('modules/interests'))
const Dashboard = lazy(() => import('modules/dashboard'))
const Notification = lazy(
    () => import('modules/notification/pages/Notification')
)
const DiscoverInternal = lazy(() => import('modules/discover-internal'))
const NotLoggedIn = lazy(() => import('modules/not-logged-in'))
const PublicProfile = lazy(() => import('modules/profile/PublicProfile'))
const Profile = lazy(() => import('modules/profile/Profile'))
const EditProfile = lazy(() => import('modules/profile/EditProfile'))
const EmailSettings = lazy(() => import('modules/profile/EmailSettings'))
const Portal = lazy(() => import('modules/portal'))
const SignupEmail = lazy(() => import('modules/signup-email'))
const Overview = lazy(() => import('modules/overview'))
const Message = lazy(() => import('modules/message'))
const NewPost = lazy(() => import('modules/new-post'))
const Drafts = lazy(() => import('modules/drafts'))
const Compose = lazy(() => import('modules/dashboard/Compose'))
const CreateCompose = lazy(() => import('modules/dashboard/CreateCompose'))
const TagScreen = lazy(() => import('modules/tag'))
const LoginPhone = lazy(() => import('modules/loginPhone'))
const ForgotPassword = lazy(() => import('modules/forgetPassword'))
const BookmarkPage = lazy(() => import('modules/bookmark/pages'))
const SearchFeed = lazy(() => import('modules/search/SearchFeed'))
const SearchTags = lazy(() => import('modules/search/SearchTags'))
const SearchUsers = lazy(() => import('modules/search/SearchUsers'))
const MyConverstaions = lazy(
    () => import('modules/converstaion/MyConversations')
)
const MutedList = lazy(() => import('modules/mutedList/MutedList'))
const GenerateAIImage = lazy(() => import('modules/new-post/GenerateAIImage'))

export const routes = [
    {
        path: '/',
        element: <Navigate to="/discover" />,
        requiresAuth: false,
    },
    {
        path: 'discover',
        element: <Discover />,
        requiresAuth: false,
    },
    {
        path: 'interests',
        element: <Interests />,
        requiresAuth: true,
    },
    {
        path: 'dashboard',
        element: <Dashboard />,
        requiresAuth: true,
    },
    {
        path: 'compose',
        element: <Compose />,
    },
    {
        path: 'generateAIImage',
        element: <GenerateAIImage />,
        requiresAuth: true,
    },
    { path: 'compose-create', element: <CreateCompose /> },
    {
        path: 'notification',
        element: <Notification />,
        requiresAuth: true,
    },
    {
        path: 'bookmark',
        element: <BookmarkPage />,
        requiresAuth: true,
    },
    {
        path: 'mutedList',
        element: <MutedList />,
        requiresAuth: true,
    },
    {
        path: 'discover/:id',
        element: <DiscoverInternal />,
        requiresAuth: false,
    },
    {
        path: 'tag/:id',
        element: <TagScreen />,
        requiresAuth: false,
    },
    {
        path: 'searchfeed',
        element: <SearchFeed />,
        requiresAuth: false,
    },
    {
        path: 'searchtags',
        element: <SearchTags />,
        requiresAuth: false,
    },
    {
        path: 'searchusers',
        element: <SearchUsers />,
        requiresAuth: false,
    },
    {
        path: 'profile',
        element: <Profile />,
        requiresAuth: true,
    },
    {
        path: 'profile/edit',
        element: <EditProfile />,
        requiresAuth: true,
    },
    {
        path: 'profile/email',
        element: <EmailSettings />,
        requiresAuth: true,
    },
    {
        path: 'profile/:id',
        element: <PublicProfile />,
        requiresAuth: false,
    },
    {
        path: 'message/new/:userId',
        element: <Message />,
        requiresAuth: true,
    },
    {
        path: 'message/:id',
        element: <Message />,
        requiresAuth: true,
    },
    {
        path: 'post/new',
        element: <NewPost />,
        requiresAuth: true,
    },
    {
        path: 'post/edit/:id',
        element: <NewPost edit />,
        requiresAuth: true,
    },
    {
        path: 'drafts',
        element: <Drafts />,
        requiresAuth: true,
    },
    {
        path: 'my-conversations',
        element: <MyConverstaions />,
        requiresAuth: true,
    },
    {
        path: '/signup',
        element: <Portal />,
        requiresAuth: false,
    },
    {
        path: '/login',
        element: <Login />,
        requiresAuth: false,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />,
        requiresAuth: false,
    },
    {
        path: '/loginPhone',
        element: <LoginPhone />,
        requiresAuth: false,
    },
    {
        path: 'signup/email',
        element: <SignupEmail />,
        requiresAuth: false,
    },
    {
        path: 'signup/overview',
        element: <Overview />,
        requiresAuth: false,
    },
    // 404
    {
        path: '*',
        element: <NotLoggedIn />,
        requiresAuth: true,
    },
]
