import { styled } from 'styled-components'
import { ReactComponent as CloseIcon } from 'assets/close.svg'
import { ReactComponent as PlusIcon } from 'assets/plus-pink.svg'

export default function TagSelf({
    title,
    isActive = false,
    customClass,
    onClick,
}: {
    title: string
    isActive?: boolean
    customClass?: string
    onClick?: () => void
}) {
    return (
        <button
            className={`${customClass} px-2 py-1 whitespace-pre	${
                !isActive ? 'bg-[#292929]' : 'bg-[#e525344d]'
            } rounded-[6px] flex gap-1 items-center`}
            type="button"
            onClick={onClick}
        >
            {/* @ts-ignore */}
            <CustomSpan isActive={isActive}>{title}</CustomSpan>
        </button>
    )
}

const CustomSpan = styled.span`
    color: #e52534;
    ${(props) =>
        // @ts-ignore
        !props.isActive && 'color: #AEAEAE;'}
    font-weight: 600;
    font-size: 0.8125rem;
`
const CustomCloseIcon = styled(CloseIcon)`
    path {
        stroke: #aeaeae;
    }
`
