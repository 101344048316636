import apiClient from './client'

type UpdateReplyRequest = {
    id: string
    title: string
    body: string
}
export const reveries = {
    generateTitle: (query: any) =>
        apiClient.get('reveries/generate_title', { params: query }),
    generateFollowup: (query: any) =>
        apiClient.get('reveries/generate_follow_up_question', {
            params: { ...query },
        }),
    getMyConversations: ({
        page,
        limit = 10,
    }: {
        page: number
        limit: number
    }) =>
        apiClient.get('reveries/my_conversations', {
            params: {
                page,
                limit,
            },
        }),
    updateReply: ({ id, title, body }: UpdateReplyRequest) =>
        apiClient.patch(`/reveries/${id}`, {
            title,
            body,
        }),
    deleteReply: (id: string) => apiClient.delete(`/reveries/${id}`),
}
