import { linkIt, UrlComponent } from 'react-linkify-it'
import React from 'react'
import { LinkIt, LinkProps } from 'react-linkify-it'

export const ShortenedUrlComponent: React.FC<
    React.PropsWithChildren<LinkProps>
> = ({ match: url, className }) => {
    return (
        <a
            target="_blank"
            href={/^www\./.exec(url) ? `http://${url}` : url}
            className="underline text-[#e52534]"
            rel="noreferrer"
            title="${url}"
        >
            {`${url.substr(0, 40)}...`}
        </a>
    )
}

export const SafeTextDisplay = ({
    text,
    truncate = 0,
}: {
    text: string
    truncate?: number
}) => {
    const urlRegex =
        /(https?:\/\/|www\.)([-\w.]+\/[\p{L}\p{Emoji}\p{Emoji_Component}!#$%&'"()*+,./\\:;=_?@[\]~-]*[^\s'",.;:\b)\]}?]|(([\w-]+\.)+[\w-]+[\w/-]))/u

    if (text) {
        const truncatedText =
            truncate > 0 ? truncateString(text, truncate) : text
        const escapedText = truncatedText
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')

        // Split the escaped text into lines
        const lines = escapedText.split('\n')

        return (
            <LinkIt
                component={(match, key) => (
                    <ShortenedUrlComponent key={key} match={match} />
                )}
                regex={urlRegex}
            >
                <div>
                    {lines.map((line: string, index: number) =>
                        line ? <p key={index}>{line}</p> : <br key={index} />
                    )}
                </div>
            </LinkIt>
        )
    } else {
        return null
    }
}

export const shortURL = (content: string) => {
    const urls = content?.match(/(https?:\/\/[^\s]+)/g)
    if (urls) {
        let updatedContent = content
        urls.forEach((url, index) => {
            updatedContent = updatedContent.replace(
                url,
                `<a href="${url}" target="_blank" class="underline text-[#e52534]" title="${url}">${url.substr(
                    0,
                    40
                )}...</a>`
            )
        })
        return updatedContent
    } else {
        return content
    }
}

export const truncateString = (str: any, num: number) => {
    if (!str || str?.length <= num) {
        return str
    }
    return str.slice(0, num) + '...'
}

export const processText = (text: string, truncate: number = 0) => {
    if (!text) return ''

    const truncatedText = truncate > 0 ? truncateString(text, truncate) : text

    // First, escape any HTML entities to prevent XSS attacks
    let escapedText = truncatedText.replace(/</g, '&lt;').replace(/>/g, '&gt;')

    escapedText = shortURL(escapedText)

    // Then replace line breaks with <br /> tags
    escapedText = escapedText.replace(/\n/g, '<br />')

    return escapedText
}

export const checkIsMobile = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    const mobileDevices = [
        'android',
        'webos',
        'iphone',
        'ipad',
        'ipod',
        'blackberry',
        'windows phone',
    ]
    return mobileDevices.some((device) => userAgent.includes(device))
}
