import { Link } from 'react-router-dom'
import { ReactComponent as MessageIcon } from 'assets/messages.svg'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import { ReactComponent as Discover } from 'assets/discover.svg'
import { ReactComponent as Profile } from 'assets/profile.svg'
import LogoutIcon from '@mui/icons-material/Logout'
import HistoryIcon from '@mui/icons-material/History'
import { ReactComponent as DefaultUser } from 'assets/user.svg'
import { useState, useEffect } from 'react'
import { UseAppContext } from 'helpers/contexts/AuthContext'
import { Bookmark, MessageSquareText } from 'lucide-react'
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded'
import { FaRegQuestionCircle } from 'react-icons/fa'
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined'
import AddIcon from '@mui/icons-material/Add'
import { cn } from 'helpers/utils'
import { Button } from './ui/button'
import { Switch } from './ui/switch'
import { Label } from './ui/label'
import { useTheme } from 'hooks/useTheme'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'

export default function Navbar() {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const { signout, user, token } = UseAppContext()
    const { theme, setTheme } = useTheme()

    const signIn = () => {
        window.location.href = '/signup'
    }

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase()
        const mobileDevices = [
            'android',
            'webos',
            'iphone',
            'ipad',
            'ipod',
            'blackberry',
            'windows phone',
        ]

        if (mobileDevices.some((device) => userAgent.includes(device))) {
            setIsCollapsed(true)
        }
    }, [])

    const path = window.location.pathname.split('/')[1]

    const handleDiscoverLinkPress = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        // We remove scroll position so this nav always brings us back to the top
        if (window.location.pathname === '/discover') {
            e.preventDefault() // Prevent the default Link behavior
            localStorage.removeItem('scrollPosition')
            window.location.reload() // Force a full page reload
        }
    }

    const navItems = [
        {
            link: 'discover',
            tab: 'discover',
            clickHandler: handleDiscoverLinkPress,
            logo: (
                <Discover
                    className={cn({
                        'm-auto': isCollapsed,
                        'transition-all': true,
                        '[&>path]:stroke-primary': path === 'discover',
                    })}
                    width={22}
                    height={28}
                />
            ),
            title: 'Discover',
        },
        {
            link: 'my-conversations',
            tab: 'my-conversations',
            logo: (
                <MessageSquareText
                    width={22}
                    height={28}
                    className={`${
                        path === 'my-conversations'
                            ? 'text-primary'
                            : 'text-[#8E8E93]'
                    }`}
                />
            ),

            title: 'My Conversations',
        },
        {
            link: 'profile',
            tab: 'messages',
            logo: !user?.attributes?.avatar?.thumbnail ? (
                <Profile
                    className={cn({
                        'm-auto': isCollapsed,
                        'transition-all': true,
                        '[&>path]:stroke-primary': path === 'profile',
                    })}
                    width={22}
                    height={28}
                />
            ) : user?.attributes?.avatar?.thumbnail === 'user.png' ? (
                <DefaultUser
                    className={cn(
                        '[&>path]:stroke-[#8E8E93] [&>path]:stroke-[4]',
                        {
                            'm-auto': isCollapsed,
                            'transition-all': true,
                            '[&>path]:stroke-primary': path === 'profile',
                        }
                    )}
                    width={22}
                    height={28}
                />
            ) : (
                <img
                    src={user?.attributes?.avatar?.thumbnail}
                    alt="profile"
                    className="w-[28px] h-[28px] rounded-full"
                    loading="lazy"
                />
            ),
            title: 'Profile',
        },
        {
            link: 'bookmark',
            tab: 'bookmark',
            logo: (
                <Bookmark
                    width={22}
                    height={28}
                    className={cn({
                        'text-[#8E8E93]': true,
                        'text-primary': path === 'bookmark',
                    })}
                />
            ),
            title: 'Bookmarks',
        },
        {
            link: 'mutedList',
            tab: 'mutedList',
            logo: (
                <VolumeOffRoundedIcon
                    width={22}
                    height={28}
                    className={`${
                        path === 'mutedList' ? 'text-primary' : 'text-[#8E8E93]'
                    }`}
                />
            ),
            title: 'Muted List',
        },
        {
            link: 'drafts',
            tab: 'drafts',
            logo: (
                <HistoryIcon
                    width={22}
                    height={28}
                    htmlColor={path === 'drafts' ? '#e52535' : '#8E8E93'}
                />
            ),
            title: 'Drafts',
        },
        {
            link: 'dashboard',
            tab: 'messages',
            logo: (
                <ChatBubbleOutlineIcon
                    width={22}
                    height={28}
                    className={`${
                        path === 'mutedList' ? 'text-primary' : 'text-[#8E8E93]'
                    }`}
                />
            ),
            title: 'DMs',
        },
    ]

    return (
        <div className="relative p-2 grid gap-2 h-[100vh] w-fit content-start border-r-[1.5px] border-solid border-[#222224]">
            <div className="grid gap-2">
                {navItems.map((item, index) => (
                    <Link
                        key={`${item.link}-${index}`}
                        to={`/${item.link}`}
                        className="text-foreground"
                        onClick={item.clickHandler}
                    >
                        <div className="p-2 rounded-[8px] transition-all flex gap-2">
                            <div className="w-[28px] flex justify-center">
                                {item.logo}
                            </div>
                            {!isCollapsed && (
                                <h6 className="text-[0.75rem] text-center font-[700] self-center">
                                    {item.title}
                                </h6>
                            )}
                        </div>
                    </Link>
                ))}
            </div>

            <a
                href="https://www.heymaven.com/about"
                target="_blank"
                rel="noreferrer"
                className="text-foreground"
            >
                <div className="p-2 rounded-[8px] flex gap-2">
                    <div className="w-[28px] flex justify-center">
                        <FaRegQuestionCircle size={20} color={'#8E8E93'} />
                    </div>
                    {!isCollapsed && (
                        <h6 className="text-[0.75rem] text-left font-[700] self-center">
                            Learn More
                        </h6>
                    )}
                </div>
            </a>

            <a
                href="https://github.com/jsecretan/maven-public/issues"
                target="_blank"
                rel="noreferrer"
                className="text-foreground"
            >
                <div className="p-2 rounded-[8px] flex gap-2">
                    <div className="w-[28px] flex justify-center">
                        <BugReportOutlinedIcon
                            width={28}
                            height={28}
                            className={'text-[#8E8E93]'}
                        />
                    </div>
                    {!isCollapsed && (
                        <h6 className="text-[0.75rem] text-left font-[700] self-center">
                            Report Issue
                        </h6>
                    )}
                </div>
            </a>

            <a
                onClick={() => {
                    if (theme === 'light') {
                        setTheme('dark')
                    } else {
                        setTheme('light')
                    }
                }}
                className="text-foreground cursor-pointer"
            >
                <div className="p-2 rounded-[8px] flex gap-2">
                    <div className="w-[28px] flex justify-center">
                        {theme === 'light' ? (
                            <LightModeIcon
                                width={20}
                                height={20}
                                htmlColor={'#8E8E93'}
                            />
                        ) : (
                            <DarkModeIcon
                                width={20}
                                height={20}
                                htmlColor={'#8E8E93'}
                            />
                        )}
                    </div>
                    {!isCollapsed && (
                        <>
                            {theme === 'light' ? (
                                <h6 className="text-[0.75rem] text-center font-[700] self-center">
                                    Light Mode
                                </h6>
                            ) : (
                                <h6 className="text-[0.75rem] text-center font-[700] self-center">
                                    Dark Mode
                                </h6>
                            )}
                        </>
                    )}
                </div>
            </a>

            <button
                className="text-foreground mt-auto"
                onClick={user && token ? signout : signIn}
            >
                <div
                    className={`p-2 hover:bg-[#FA9573] rounded-[8px] transition-all flex gap-2`}
                >
                    <div className="w-[28px] flex justify-center">
                        <LogoutIcon
                            className={
                                isCollapsed
                                    ? 'm-auto text-[#8E8E93]'
                                    : 'text-[#8E8E93]'
                            }
                            width={22}
                            height={28}
                        />
                    </div>
                    {!isCollapsed && (
                        <>
                            {user && token ? (
                                <h6 className="text-[0.75rem] text-center font-[700] self-center">
                                    Sign out
                                </h6>
                            ) : (
                                <h6 className="text-[0.9rem] text-center font-[700] self-center">
                                    Signup / Login
                                </h6>
                            )}
                        </>
                    )}
                </div>
            </button>

            <div className="p-2">
                <Link to={'/post/new'}>
                    {/* TODO: Remove important */}
                    {isCollapsed ? (
                        <AddIcon
                            className={'m-auto text-primary'}
                            width={22}
                            height={28}
                        />
                    ) : (
                        <Button className="!bg-primary rounded-full text-white h-[30px] justify-center px-6 font-semibold text-base">
                            New Post
                        </Button>
                    )}
                </Link>
            </div>

            {/* <button
        className={`absolute bottom-2 right-[-28px] bg-[#FC6734] p-2 rounded-tr-[8px] rounded-br-[8px] transition-all z-[999]`}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Chevron className={!isCollapsed ? "rotate-[180deg]" : ""} />
      </button> */}
        </div>
    )
}
