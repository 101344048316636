import axios, { AxiosError, AxiosResponse } from 'axios'
import { asyncLocalStorage } from 'helpers/hooks/asyncLocalStorage'

const onResponse = (response: AxiosResponse): AxiosResponse => {
    // console.info(`[response] [${JSON.stringify(response)}]`);
    return response
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[response error] [${JSON.stringify(error)}]`)
    // if (error.response?.status === 401) {
    //   localStorage.removeItem("token");
    //   // toast.error('Session expired. Please login again.');
    //   window.location.replace("/login");
    // }
    return Promise.reject(error)
}

const apiClient = axios.create({
    baseURL: 'https://' + process.env.REACT_APP_API_DOMAIN + '/api/v1/',
})

const apiClientV2 = axios.create({
    baseURL: 'https://' + process.env.REACT_APP_API_DOMAIN + '/api/v2/',
})

// const token = localStorage.getItem("token");

apiClient.interceptors.request.use(
    async (config: any) => {
        // await getData("auth_token").then((res: any) => {
        // config.headers["Authorization"] = `Bearer ${res}`;
        // });
        await asyncLocalStorage.getItem('token').then((res: any) => {
            if (res) config.headers['Authorization'] = res
        })

        // config.headers["Authorization"] = `Bearer ${token}`;

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

apiClientV2.interceptors.request.use(
    async (config: any) => {
        // await getData("auth_token").then((res: any) => {
        // config.headers["Authorization"] = `Bearer ${res}`;
        // });
        await asyncLocalStorage.getItem('token').then((res: any) => {
            if (res) config.headers['Authorization'] = res
        })

        // config.headers["Authorization"] = `Bearer ${token}`;

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

apiClient.interceptors.response.use(onResponse, onResponseError)
apiClientV2.interceptors.response.use(onResponse, onResponseError)

export default apiClient
export { apiClientV2 }
