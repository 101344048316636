import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as Notification } from 'assets/notification.svg'
import { api } from 'helpers/api'
import { UseAppContext } from 'helpers/contexts/AuthContext'

export default function NotificationIcon() {
    const [unreadNotifications, setUnreadNotifications] = useState(0)
    const { user, token } = UseAppContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (user && token) {
            fetchData()
        }
    }, [])

    const fetchData = async () => {
        const res = await api.notification.getNotifications(1)
        const { data } = res.data
        const counts = data.filter(
            (item: any) => item.attributes.status === 'unread'
        ).length
        setUnreadNotifications(counts)
    }
    return (
        <div className="text-foreground relative flex items-center">
            <Notification
                height={16}
                className="ml-4 cursor-pointer"
                onClick={() => navigate('/notification')}
            />
            {unreadNotifications > 0 && (
                <div
                    className="flex justify-center items-center absolute w-4 h-4 rounded-full bg-red-700"
                    style={{
                        right: -4,
                        top: -4,
                        height: 14,
                        width: 14,
                    }}
                    // style={{
                    //   justifyContent: "center",
                    //   alignItems: "center",
                    //   borderWidth: 0.5,
                    //   backgroundColor: colors[theme].primary,
                    //   borderColor: colors[theme].primary,
                    //   position: "absolute",
                    //   top: -4,
                    //   right: -4,
                    //   height: 15,
                    //   width: 16,
                    //   // paddingHorizontal: 2,
                    //   borderRadius: 99,
                    // }}
                >
                    <p
                        // className=""
                        style={{ fontSize: 12 }}
                        //   allowFontScaling
                        //   minimumFontScale={0.5}
                        //   style={{ color: colors[theme].text, fontSize: 12 }}
                    >
                        {unreadNotifications > 9 ? '9+' : unreadNotifications}
                    </p>
                </div>
            )}
        </div>
    )
}
