import { api } from 'helpers/api'
import { useState } from 'react'
import { Button } from 'components/ui/button'
import toast from 'react-hot-toast'
import { UseAppContext } from 'helpers/contexts/AuthContext'
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'

export const EnterNameModal = ({
    show,
    handleClose,
}: {
    show: boolean
    handleClose: any
}) => {
    const [name, setName] = useState('')
    const { user, refetchUserData } = UseAppContext()
    const windowTitle = 'What do you want to be called?'

    const handleSubmit = (event: any) => {
        event.preventDefault()

        if (name.length < 1) {
            toast('Please enter a name')
            return
        }

        api.profile
            .updateProfile({ user: { name: name } })
            .then((res) => {
                refetchUserData()
                handleClose()
            })
            .catch((err) => {
                console.log({ err })
                handleClose()
            })

        handleClose()
    }

    return (
        <Dialog open={show} onOpenChange={handleClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle className="text-left">
                        {windowTitle}
                    </DialogTitle>
                </DialogHeader>
                <DialogClose />

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <Input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Name"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <Button type="submit">Save</Button>
                </form>
            </DialogContent>
        </Dialog>
    )
}
