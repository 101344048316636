// generate a profile api client
// Path: src/helpers/api/profile.ts
import apiClient from './client'
export const profile = {
    getReveries: (query: any) =>
        apiClient.get('reveries', { params: { ...query } }),
    updateProfile: (data: any) => apiClient.patch('auth/profiles', data),
    updatePassword: (data: any) => apiClient.post('auth/passwords/reset', data),
    getPublicReveries: (query: any, id: string) =>
        apiClient.get(`public/users/${id}/posts`, { params: { ...query } }),
    getPublicProfile: (id: string) => apiClient.get(`public/users/${id}`),
    register: (data: any) => apiClient.post('auth/registrations', data),
    checkEmail: (email: string) =>
        apiClient.get(`auth/registrations/verify_email?user[email]=${email}`),
    getBookmarks: () => apiClient.get('bookmarks'),
    addTag: (name: string) =>
        apiClient.post('tags', {
            tag: { name: name },
        }),
    removeTag: (id: string) => apiClient.delete(`user_tags/${id}`),

    unmuteTag: (id: string) => apiClient.delete(`muted_tags/${id}`),
    unmuteUser: (id: string) => apiClient.delete(`mute_users/${id}`),
    getMutedThreads: (data: any) =>
        apiClient.get('muted_threads', { params: { ...data } }),
    getMutedTags: (data: any) =>
        apiClient.get('muted_tags', { params: { ...data } }),
    getMutedUsers: (data: any) =>
        apiClient.get('mute_users', { params: { ...data } }),
    deleteProfile: () => apiClient.delete(`/auth/profiles`),
}
