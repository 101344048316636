import React, { useState, useEffect } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'

const DownloadBanner = () => {
    const [isVisible, setIsVisible] = useState(false)
    const [message, setMessage] = useState('')
    const [downloadLink, setDownloadLink] = useState(
        'https://apps.apple.com/bz/app/maven-the-serendipity-network/id6449607929'
    )

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor
        const isiOS = /iPad|iPhone|iPod/.test(userAgent)
        const isAndroid = /android/i.test(userAgent)
        const isMobile = isiOS || isAndroid
        const isDismissed = localStorage.getItem('bannerDismissed') === 'true'

        if (isMobile && !isDismissed) {
            setIsVisible(true)
            if (isAndroid) {
                setDownloadLink(
                    'https://play.google.com/store/apps/details?id=com.heymaven.maven'
                )
            }
        }
    }, [])

    const dismissBanner = () => {
        localStorage.setItem('bannerDismissed', 'true')
        setIsVisible(false)
    }

    if (!isVisible) return null

    return (
        <div className="relative banner bg-[#e525344d] width-full py-1 text-xs text-foreground font-bold">
            <div className="text-center">
                <a href={downloadLink}>
                    Maven is better in the app, tap here to download
                </a>
            </div>
            <button
                onClick={dismissBanner}
                className="ml-4 absolute top-1/2 -translate-y-1/2 right-1"
            >
                <CloseIcon
                    width={20}
                    height={20}
                    className="[&>path]:stroke-muted"
                />
            </button>
        </div>
    )
}

export default DownloadBanner
