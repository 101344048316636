import { AddBookmarkRequest, BookmarkableTypes } from 'modules/bookmark/types'
import { apiClientV2 } from './client'
export const bookmark = {
    getBookmarks: (
        page: number,
        filters?: { [key: string]: string | number | boolean }
    ) =>
        apiClientV2.get('bookmarks', {
            params: {
                page,
                filters: filters,
            },
        }),
    getBookmarksNew: (data: any) =>
        apiClientV2.get('bookmarks', {
            params: {
                ...data,
            },
        }),
    addBookmark: async (input: AddBookmarkRequest) =>
        await apiClientV2.post('bookmarks', input),
    removeBookmark: async ({
        id,
        type,
    }: {
        id: number
        type: BookmarkableTypes
    }) =>
        await apiClientV2.delete(`bookmarks/${id}`, {
            params: {
                bookmarkable_type: type,
            },
        }),
}
