import apiClient from 'helpers/api/client'
import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import toast from 'react-hot-toast'

export const ReportModel = ({
    type,
    show,
    handleClose,
    mainId,
}: {
    type: string
    show: boolean
    handleClose: any
    mainId: any
}) => {
    const [commentBody, setCommentBody] = useState('')
    const [loading, setLoading] = useState(false)
    const title =
        type === 'feed' ? 'Report Post, Message or Comment' : 'Report Account'

    const handleSubmit = (event: any) => {
        event.preventDefault()

        // return;
        if (commentBody.length < 10) {
            toast('Please enter at least 10 characters')
            return
        }

        setLoading(true)
        let params = {}
        if (type === 'feed') {
            params = {
                flag_reverie: {
                    reverie_id: mainId,
                    note_attributes: {
                        content: commentBody,
                    },
                },
            }
        } else if (type === 'user') {
            params = {
                reported_user: {
                    reported_user_id: mainId,
                    note_attributes: {
                        content: commentBody,
                    },
                },
            }
        }

        apiClient
            .post(type === 'feed' ? '/flag_reveries' : 'reported_users', params)
            .then((res) => {
                setLoading(false)
                handleClose()
                toast('Reported successfully')
                window.location.reload()
            })
            .catch((err) => {
                console.log({ err })
                setLoading(false)
                handleClose()
            })

        handleClose()
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="w-100 text-white border-0">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="w-100 text-white border-0">
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Control
                            name="message"
                            as="textarea"
                            onChange={(e) => setCommentBody(e.target.value)}
                            placeholder="Please tell us why with as much detail as possible..."
                        />
                    </Form.Group>
                    <div className="flex flex-row items-center justify-end gap-4">
                        <button
                            className="px-2 py-1 rounded-full disabled:bg-[#E52534] bg-[#E52534] hover:bg-[#e52535d5] hover:scale-[1.02] text-white font-[600] w-fit rounded-[8px] transition-all text-[1rem] flex"
                            type="submit"
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                        <button
                            className="px-2 py-1 rounded-full bg-transparent hover:scale-[1.02] text-[#E52534] font-[600] w-fit rounded-[8px] transition-all text-[1rem]"
                            style={{ border: '1px solid #E52534' }}
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                    </div>
                    {/* <Button variant="primary" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button> */}
                </Form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}
