export enum BookmarkableTypes {
    reverie = 'Reverie',
    idea = 'Idea',
    user = 'User',
}

export interface AddBookmarkRequest {
    bookmark: {
        bookmarkable_id: string
        bookmarkable_type: BookmarkableTypes
    }
}
