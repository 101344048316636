import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface UserTagsState {
    userTags: any[]
    loading?: boolean
    serendipityBoost: boolean
    selectedTags: any[]
    isAllTagsSelected?: boolean
}

const initialState: UserTagsState = {
    userTags: [],
    loading: false,
    serendipityBoost: false,
    selectedTags: [],
    isAllTagsSelected: false,
}

export const userTagsSlice = createSlice({
    name: 'userTags',
    initialState,
    reducers: {
        setUserTagsLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setUserTags: (state, action: PayloadAction<any[]>) => {
            state.userTags = action.payload
        },
        setSerendipityBoost: (state, action: PayloadAction<boolean>) => {
            state.serendipityBoost = action.payload
        },
        setAllTagsSelected: (state, action: PayloadAction<boolean>) => {
            state.isAllTagsSelected = action.payload
        },
        setSelectedTags: (state, action: PayloadAction<any[]>) => {
            state.selectedTags = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setUserTags,
    setUserTagsLoading,
    setSerendipityBoost,
    setAllTagsSelected,
    setSelectedTags,
} = userTagsSlice.actions

export default userTagsSlice.reducer
