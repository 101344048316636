// export everything in folder with api prefix
// Path: src/helpers/api/index.ts
// export * from "./profile";
// export * from "./discover";
// export * from "./sparks";
import { profile } from './profile'
import { sparks } from './sparks'
import { discover } from './discover'
import { reveries } from './reveries'
import { connect } from './connect'
import { notification } from './notification'
import { tag } from './tag'
import { bookmark } from './bookmark'
import { thread } from './thread'

export const api = {
    profile,
    sparks,
    discover,
    reveries,
    connect,
    tag,
    notification,
    bookmark,
    thread,
}
