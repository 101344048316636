import { cn } from 'helpers/utils'
import { LoaderCircle } from 'lucide-react'
import { ComponentProps } from 'react'

export const Spinner = (props: ComponentProps<typeof LoaderCircle>) => {
    return (
        <LoaderCircle
            className={cn(
                'text-primary animate-spin stroke-[4] w-5 h-5',
                props.className
            )}
        />
    )
}
