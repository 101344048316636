import * as React from 'react'
import { styled } from '@mui/material/styles'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Icons from 'assets'
import logo from '../../assets/logo.svg'

export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    '&': {
        width: 72,
        height: 52,
    },
    '& .MuiSwitch-switchBase': {
        padding: 4,
        backgroundColor: '#c0bfc0',
        borderRadius: '50%',
        // zIndex: -1,
        top: 12,
        left: 12,
        '&.Mui-checked': {
            backgroundColor: '#79141f',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor:
                    theme.palette.mode === 'dark' ? '#e525344d' : '#480b11',
            },
        },
    },

    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
    '& .MuiSwitch-track': {
        borderRadius: 20,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    },
}))
