import apiClient from './client'
export const notification = {
    getNotifications: (
        page: number,
        filters?: { [key: string]: string | number | boolean }
    ) =>
        apiClient.get('notifications', {
            params: {
                page,
                filters: filters,
            },
        }),
    markAsRead: (notificationIdArray: string[]) =>
        apiClient.patch('notifications/mark_read', {
            notification_ids: notificationIdArray,
        }),
}
