import { Button } from 'components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from 'components/ui/dialog'
import { Slider } from 'components/ui/slider'
import { api } from 'helpers/api'
import { UseAppContext } from 'helpers/contexts/AuthContext'
import { ComponentProps, useMemo, useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Loader2 } from 'lucide-react'
import { toast } from 'react-hot-toast'

export const SerendipitySettingsDialog = (
    props: ComponentProps<typeof Dialog>
) => {
    const { user, refetchUserData } = UseAppContext()
    const serendipity = useRef(0)
    const queryClient = useQueryClient()

    const editUserMutation = useMutation({
        mutationFn: api.profile.updateProfile,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['discoverBoostOff'],
            })
            toast.success('Serendipity ratio updated successfully.', {
                position: 'bottom-right',
            })
            refetchUserData()
            if (props.onOpenChange) {
                props.onOpenChange(false)
            }
        },
    })

    const userSerendipityRatio = useMemo(() => {
        const serendipity = user?.attributes?.serendipity_ratio
        if (!serendipity) {
            return [0]
        }
        if (serendipity && typeof serendipity !== 'number') {
            return [0]
        }
        return [serendipity]
    }, [user])

    const sliderChangeHandler = (value: number[]) => {
        serendipity.current = value.shift() ?? 0
    }

    const saveHandler = () => {
        editUserMutation.mutate({
            user: {
                serendipity_ratio: serendipity.current,
            },
        })
    }

    return (
        <Dialog {...props}>
            {/* TODO: remove important */}
            <DialogContent className="text-foreground !border-border max-w-3xl">
                <DialogHeader>
                    <DialogTitle>Serendipity</DialogTitle>
                    <DialogDescription>
                        Select the amount of &apos;serendipity&apos; to bring
                        into your feed
                    </DialogDescription>
                </DialogHeader>
                <Slider
                    onValueChange={sliderChangeHandler}
                    step={0.1}
                    min={0}
                    max={1}
                    defaultValue={userSerendipityRatio}
                />

                <div className="flex items-center justify-between">
                    <small>Only show selected interests</small>
                    <small>Show me everything</small>
                </div>
                <div className="my-2 text-right">
                    {/* TODO: remove important */}
                    <Button
                        disabled={editUserMutation.isLoading}
                        onClick={saveHandler}
                        size={'sm'}
                        className="bg-background/20 text-foreground !border-border border hover:!bg-foreground/10"
                    >
                        {editUserMutation.isLoading && (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Save
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}
