import { api } from 'helpers/api'
import { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'components/Button'

export const EditModal = ({
    type,
    show,
    handleClose,
    id,
    onEditSuccess,
}: {
    type: string
    show: boolean
    handleClose: any
    id: string
    onEditSuccess?: (data: any) => void
}) => {
    const [title, setTitle] = useState('')
    const [body, setBody] = useState('')
    const [loading, setLoading] = useState(false)
    const windowTitle = type === 'post' ? 'Edit Post' : 'Edit Reply'

    const handleSubmit = (event: any) => {
        event.preventDefault()
        if (type === 'reverie' && body.length < 40) {
            toast('Please enter at least 40 characters')
            return
        }
        setLoading(true)
        api.discover
            // @ts-ignore
            .updatePost(id, { title: title, body: body })
            .then((res) => {
                onEditSuccess && onEditSuccess(res?.data?.data)
                setLoading(false)
                handleClose()
                toast('Edited successfully')
                // window.location.reload();
            })
            .catch((err) => {
                console.log({ err })
                setLoading(false)
                handleClose()
            })

        handleClose()
    }

    useEffect(() => {
        if (show) {
            setLoading(true)
            api.discover
                .getMatch(id)
                .then((res) => {
                    console.log(type)
                    setLoading(false)
                    setTitle(res?.data?.data?.attributes?.title)
                    setBody(res?.data?.data?.attributes?.body)
                })
                .catch((err) => {
                    console.log({ err })
                    setLoading(false)
                })
        }
    }, [id, show])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton className="border-[#363636]">
                <Modal.Title className="w-100 text-white border-0">
                    {windowTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="w-100 text-white">
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupMessage">
                        {type === 'post' && (
                            <Form.Control
                                name="title"
                                type={'textarea'}
                                rows={1}
                                as="textarea"
                                className="mb-2 "
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Title"
                                value={title}
                            />
                        )}
                        <Form.Control
                            name="body"
                            as="textarea"
                            rows={3}
                            className="min-h-[100px] mb-2"
                            onChange={(e) => setBody(e.target.value)}
                            placeholder="Post"
                            value={body}
                        />
                    </Form.Group>
                    {/* <PrimaryButton
            className="px-2 py-1 rounded-full"
            onClick={(e) => handleSubmit(e)}
            type="submit"
          >
            Save
          </PrimaryButton> */}
                    <div className="flex flex-row items-center justify-end gap-4">
                        <button
                            className="px-2 py-1 rounded-full disabled:bg-[#E52534] bg-[#E52534] hover:bg-[#e52535d5] hover:scale-[1.02] text-white font-[600] w-fit rounded-[8px] transition-all text-[1rem] flex"
                            type="submit"
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                        <button
                            className="px-2 py-1 rounded-full bg-transparent hover:scale-[1.02] text-[#E52534] font-[600] w-fit rounded-[8px] transition-all text-[1rem]"
                            style={{ border: '1px solid #E52534' }}
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
