import { ReactComponent as UserIcon } from 'assets/user.svg'
import { cn } from 'helpers/utils'
import { ImgHTMLAttributes } from 'react'

interface AvatarOrDefaultImageProps
    extends ImgHTMLAttributes<HTMLImageElement> {
    user: any
    width: number
    height: number
}
export default function AvatarOrDefaultImage({
    user,
    width = 100,
    height = 100,
    className,
}: AvatarOrDefaultImageProps) {
    const url = user
        ? user.image
            ? user.image
            : user?.avatar?.thumbnail
              ? user?.avatar?.thumbnail
              : user?.attributes?.avatar.thumbnail
                ? user?.attributes?.avatar.thumbnail
                : 'user.png'
        : 'user.png'
    return url === 'user.png' ? (
        <>
            <UserIcon
                className="rounded-full [&>path]:stroke-foreground"
                width={width}
                height={height}
            />
        </>
    ) : (
        <img
            src={url}
            width={width}
            height={height}
            className={cn('rounded-full', className)}
            alt={user?.name}
        />
    )
}
