import SearchIcon from 'assets/search.svg'
import BlackLayout from 'layouts/BlackLayout'
import { useEffect, useState } from 'react'
// import UserAdd from "assets/ic_user-add.svg";
import { ReactComponent as CloseIcon } from 'assets/closeWColor.svg'
import SettingIcon from 'assets/setting.svg'
import defaultAvatar from 'assets/user.svg'
import { MaterialUISwitch } from 'components/BoostSwitch/BoostSwitch'
import InviteModel from 'components/Models/InviteModel'
import Tag from 'components/Tag'
import TagSelf from 'components/TagSelf'
import { api } from 'helpers/api'
import { UseAppContext } from 'helpers/contexts/AuthContext'
import { useDebounce } from 'helpers/hooks/useDebounce'
import { BookmarkIcon } from 'lucide-react'
import { useAddBookmark } from 'modules/bookmark/hooks/useAddBookmark'
import { useRemoveBookmark } from 'modules/bookmark/hooks/useRemoveBookmark'
import { BookmarkableTypes } from 'modules/bookmark/types'
import NotificationIcon from 'modules/notification/components/NotificationIcon'
import { Spinner } from 'components/ui/spinner'
import { useInView } from 'react-intersection-observer'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'redux/reduxHooks/useRedux'
import { useUserTagsHook } from 'redux/reduxHooks/useUserTagsHooks'
import {
    setAllTagsSelected,
    setSelectedTags,
    setSerendipityBoost,
} from 'redux/slices/userTagsSlice'
import { ReactComponent as LogoPlain } from '../../assets/ic_logo_plain.svg'
import ConnectFollowers from './components/Followers'
import ConnectFollowing from './components/Following'
import ConnectNewMatches from './components/NewMatches'
import { cn } from 'helpers/utils'
import AvatarOrDefaultImage from 'components/AvatarOrDefaultImage'

export default function Connect() {
    const [activeTab, setActiveTab] = useState<string>('new-matches')
    const [searchText, setSearchText] = useState<string>('')
    const { ref, inView } = useInView()
    const { userTagsState, getTagsAtSerendipityLevel } = useUserTagsHook()
    const [tags, setTags] = useState<any[]>([])
    const { user } = UseAppContext()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [searchDataState, setSearchDataState] = useState<
        InfiniteData<any> | undefined
    >()

    const debouncedSearchText = useDebounce(searchText, 500)
    const tabs = [
        {
            name: 'new-matches',
            text: 'You might like',
            component: ConnectNewMatches,
        },
        {
            name: 'following',
            text: 'You like them',
            component: ConnectFollowing,
        },
        {
            name: 'followers',
            text: 'They like you',
            component: ConnectFollowers,
        },
    ]

    // Fetches from a paginated API with param `page`
    const fetchSearchData = async ({
        pageParam = {
            page: '1',
            limit: '5',
            query: debouncedSearchText,
        },
    }) => {
        let newParams: {
            page: string
            limit: string
            query: string
        } = {
            ...pageParam,
        }
        const res = await api.connect.getSearchedUsers(newParams)
        return res.data
    }

    const {
        data: searchData,
        isFetchingNextPage: searchIsFetchingNextPage,
        fetchNextPage: searchFetchNextPage,
        hasNextPage: searchHasNextPage,
        isLoading: searchIsLoading,
    } = useInfiniteQuery(['searchUser', debouncedSearchText], fetchSearchData, {
        enabled: debouncedSearchText.length > 0,
        getNextPageParam: (lastPage) => {
            if (lastPage.meta.current_page < lastPage.meta.total_pages) {
                let params: {
                    page: string
                    limit: number
                    query: string
                } = {
                    page: lastPage.meta.next_page,
                    query: debouncedSearchText,
                    limit: 5,
                }
                return (
                    // @ts-ignore
                    lastPage.meta.next_page && params
                )
            }
        },
    })

    useEffect(() => {
        setSelectedTags([])
        const tagsAtSerendipityLevel = getTagsAtSerendipityLevel(
            userTagsState.serendipityBoost ? 2 : 0
        )
        setTags(tagsAtSerendipityLevel)
    }, [userTagsState.serendipityBoost])

    useEffect(() => {
        if (userTagsState.userTags.length !== 0) {
            const tagsAtSerendipityLevel = getTagsAtSerendipityLevel(
                userTagsState.serendipityBoost ? 2 : 0
            )
            setTags(tagsAtSerendipityLevel)
        }
    }, [userTagsState.userTags])

    useEffect(
        () => {
            if (searchData) {
                setSearchDataState(searchData)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [searchData]
    )

    useEffect(() => {
        if (inView) {
            searchFetchNextPage()
        }
    }, [inView])

    const styles = {
        activeTab: 'text-foreground font-bold border-b !border-foreground p-2',
        inactiveTab: 'text-secondary font-bold p-2',
    }

    return (
        <BlackLayout noChildrenPadding>
            <div className="h-screen overflow-y-auto gap-4 pb-4">
                <div className="max-w-[1200px] w-full mx-auto px-8">
                    <div className="flex justify-between items-center mt-4 mb-8">
                        <h4 className="text-2xl text-foreground font-bold">
                            People
                        </h4>
                        <div className="flex items-center">
                            <InviteModel />
                            <div className="flex overflow-y-auto gap-2 items-center w-full mr-3">
                                <Link
                                    to={`/interests`}
                                    className="items-center"
                                >
                                    <img
                                        src={SettingIcon}
                                        alt="home"
                                        className="w-6 h-6"
                                        loading="lazy"
                                    />
                                </Link>
                            </div>
                            <div className="mr-4 d-inline-flex">
                                <MaterialUISwitch
                                    icon={
                                        <LogoPlain
                                            height={20}
                                            width={20}
                                            color="white"
                                        />
                                    }
                                    checkedIcon={
                                        <LogoPlain
                                            height={20}
                                            width={20}
                                            color="white"
                                        />
                                    }
                                    checked={userTagsState.serendipityBoost}
                                    onChange={() => {
                                        setSearchText('')
                                        setSearchDataState(undefined)
                                        dispatch(
                                            setSerendipityBoost(
                                                !userTagsState.serendipityBoost
                                            )
                                        )
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                            <div className="relative">
                                {searchText.length > 0 && (
                                    <CloseIcon
                                        height={22}
                                        onClick={() => {
                                            setSearchText('')
                                            setSearchDataState(undefined)
                                        }}
                                        width={22}
                                        color="#C7C7CC"
                                        className="absolute  top-1/2 transform -translate-y-1/2 right-0"
                                    />
                                )}
                                <input
                                    type="text"
                                    placeholder="Search Users on Maven"
                                    value={searchText}
                                    onChange={(e) =>
                                        setSearchText(e.target.value)
                                    }
                                    className="bg-[transparent] px-4 w-[12.5rem] py-1 text-secondary placeholder-secondary text-sm focus-visible:outline-none border-b !border-border"
                                />
                                <img
                                    src={SearchIcon}
                                    alt="search"
                                    className="absolute top-1/2 transform -translate-y-1/2 left-0"
                                    loading="lazy"
                                />
                            </div>
                            <NotificationIcon />
                        </div>
                    </div>
                    {searchText.length === 0 ? (
                        <div className="grid gap-4 mt-3">
                            <div className="flex gap-4 border-b !border-border">
                                {tabs.map((tab) => {
                                    return (
                                        <button
                                            key={tab.name}
                                            className={
                                                activeTab === tab.name
                                                    ? styles.activeTab
                                                    : styles.inactiveTab
                                            }
                                            onClick={() =>
                                                setActiveTab(tab.name)
                                            }
                                        >
                                            {tab.text}
                                        </button>
                                    )
                                })}
                            </div>
                            <div className="flex gap-8">
                                <div className="flex-auto">
                                    {tabs.map((tab) => {
                                        if (tab.name === activeTab) {
                                            const Component = tab.component
                                            return <Component key={tab.name} />
                                        }
                                        return null
                                    })}
                                </div>

                                <div className="flex flex-col items-start gap-2 overflow-auto">
                                    {user.attributes.is_admin && (
                                        <TagSelf
                                            title="All Posts"
                                            customClass="min-w-fit"
                                            // @ts-ignore
                                            isActive={
                                                userTagsState.isAllTagsSelected
                                            }
                                            onClick={() => {
                                                setSearchText('')
                                                setSearchDataState(undefined)
                                                dispatch(
                                                    setSerendipityBoost(false)
                                                )
                                                dispatch(
                                                    setAllTagsSelected(
                                                        !userTagsState.isAllTagsSelected
                                                    )
                                                )
                                            }}
                                        />
                                    )}
                                    {tags.length > 0 &&
                                        tags.map((item, index) => {
                                            const isTagActive =
                                                userTagsState.selectedTags?.find(
                                                    (tag: any) =>
                                                        tag.name === item.name
                                                )
                                            return (
                                                <TagSelf
                                                    title={item.name}
                                                    customClass="min-w-fit"
                                                    key={index}
                                                    // @ts-ignore
                                                    isActive={isTagActive}
                                                    onClick={() => {
                                                        setSearchText('')
                                                        setSearchDataState(
                                                            undefined
                                                        )
                                                        navigate(
                                                            `/tag/${item.id}`
                                                        )
                                                        // if (!isTagActive) {
                                                        //   // add to selected tags
                                                        //   userTagsState.isAllTagsSelected &&
                                                        //     dispatch(setAllTagsSelected(false));
                                                        //   dispatch(
                                                        //     setSelectedTags([
                                                        //       //...userTagsState.selectedTags,
                                                        //       item,
                                                        //     ])
                                                        //   );
                                                        // } else {
                                                        //   // remove from selected tags

                                                        //   dispatch(
                                                        //     setSelectedTags(
                                                        //       userTagsState.selectedTags.filter(
                                                        //         (tag: any) => tag.name !== item.name
                                                        //       )
                                                        //     )
                                                        //   );
                                                        // }
                                                    }}
                                                />
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    ) : searchIsLoading ? (
                        <div className="text-center">
                            <Spinner />
                        </div>
                    ) : (
                        <div className="flex gap-8">
                            <div className="flex-auto grid gap-8">
                                {searchDataState?.pages?.map((page: any) => {
                                    return page?.data?.map(
                                        (item: any, index: number) => {
                                            return (
                                                <div className="grid gap-8">
                                                    <PersonCard data={item} />;
                                                </div>
                                            )
                                        }
                                    )
                                })}
                            </div>

                            <div className="flex flex-col flex-wrap gap-2 overflow-auto">
                                {user.attributes.is_admin && (
                                    <TagSelf
                                        title="All Posts"
                                        customClass="min-w-fit"
                                        // @ts-ignore
                                        isActive={
                                            userTagsState.isAllTagsSelected
                                        }
                                        onClick={() => {
                                            setSearchText('')
                                            setSearchDataState(undefined)
                                            dispatch(setSerendipityBoost(false))
                                            dispatch(
                                                setAllTagsSelected(
                                                    !userTagsState.isAllTagsSelected
                                                )
                                            )
                                        }}
                                    />
                                )}
                                {tags.length > 0 &&
                                    tags.map((item, index) => {
                                        const isTagActive =
                                            userTagsState.selectedTags?.find(
                                                (tag: any) =>
                                                    tag.name === item.name
                                            )
                                        return (
                                            <TagSelf
                                                title={item.name}
                                                customClass="min-w-fit"
                                                key={index}
                                                // @ts-ignore
                                                isActive={isTagActive}
                                                onClick={() => {
                                                    setSearchText('')
                                                    setSearchDataState(
                                                        undefined
                                                    )
                                                    navigate(`/tag/${item.id}`)
                                                    // if (!isTagActive) {
                                                    //   // add to selected tags
                                                    //   userTagsState.isAllTagsSelected &&
                                                    //     dispatch(setAllTagsSelected(false));
                                                    //   dispatch(
                                                    //     setSelectedTags([
                                                    //       // ...userTagsState.selectedTags,
                                                    //       item,
                                                    //     ])
                                                    //   );
                                                    // } else {
                                                    //   // remove from selected tags

                                                    //   dispatch(
                                                    //     setSelectedTags(
                                                    //       userTagsState.selectedTags.filter(
                                                    //         (tag: any) => tag.name !== item.name
                                                    //       )
                                                    //     )
                                                    //   );
                                                    // }
                                                }}
                                            />
                                        )
                                    })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </BlackLayout>
    )
}

export function PersonCard({
    data,
    refetch = null,
    tagName = null,
    handleUnmute = null,
}: {
    data: {
        attributes: {
            avatar: {
                original: string
                thumbnail: string
            }
            name: string
            experience: string
            all_tags: {
                id: number
                name: string
            }[]
            reactions: []
            bookmarked: boolean
        }
        id: string
    }
    refetch?: any
    tagName?: string | null
    handleUnmute?: any
}) {
    const { user: userData, refetchUserData } = UseAppContext()
    const userOnCard = data?.attributes

    const isReacted: any = userOnCard?.reactions?.find((item: any) => {
        return item.user_id.toString() === userData?.id.toString()
    })

    const isFollowing = isReacted ? true : false

    const navigate = useNavigate()
    const addBookmarkMutation = useAddBookmark()
    const removeBookmarkMutation = useRemoveBookmark()
    const [isBookmarked, setIsBookmarked] = useState(false)

    const toggleBookmark = async () => {
        if (isBookmarked) {
            await removeBookmarkMutation.mutateAsync({
                id: parseInt(data?.id),
                type: BookmarkableTypes.user,
            })
        } else {
            await addBookmarkMutation.mutateAsync({
                bookmark: {
                    bookmarkable_id: data.id,
                    bookmarkable_type: BookmarkableTypes.user,
                },
            })
        }
        setIsBookmarked(!isBookmarked)
    }

    useEffect(() => {
        if (data?.attributes?.bookmarked) {
            setIsBookmarked(true)
        }
    }, [data])

    return (
        <div className="flex gap-4">
            <Link to={`/profile/${data.id}`} className="w-10 h-10">
                <AvatarOrDefaultImage
                    user={data?.attributes}
                    className="w-full h-full"
                    width={40}
                    height={40}
                />
            </Link>
            <div className="grid gap-1">
                <Link
                    to={`/profile/${data.id}`}
                    className="text-foreground font-bold"
                >
                    {data?.attributes?.name}
                </Link>

                <div className="flex flex-wrap max-w-[450px] mt-2 gap-2">
                    {data?.attributes?.all_tags?.length >= 15
                        ? data?.attributes?.all_tags
                              ?.slice(0, 15)
                              ?.map((tag, index) => {
                                  const isTagActive =
                                      userData &&
                                      userData?.attributes.tags?.find(
                                          (userTag: any) =>
                                              userTag.name === tag.name
                                      )
                                  return (
                                      <Tag
                                          title={tag.name}
                                          customClass="min-w-fit"
                                          key={index}
                                          // @ts-ignore
                                          isActive={isTagActive}
                                          onClick={() => {
                                              if (
                                                  tagName &&
                                                  tagName === tag.name
                                              )
                                                  return
                                              navigate(
                                                  `/tag/${tag.id}?sourceType=User&sourceId=${data.id}`
                                              )

                                              // navigate(`/tag/${tag.id}/${JSON.stringify(tag)}`);

                                              // if (!isTagActive) {
                                              //   AddTag(tag.name);
                                              //   refetchUserData();
                                              // } else {
                                              //   RemoveTag(tag.id.toString());
                                              //   refetchUserData();
                                              // }
                                          }}
                                      />
                                  )
                              })
                        : data?.attributes?.all_tags?.map((tag) => {
                              const isTagActive =
                                  userData &&
                                  userData.attributes.tags?.find(
                                      (userTag: any) =>
                                          userTag.name === tag.name
                                  )
                              return (
                                  <Tag
                                      title={tag.name}
                                      key={tag.id}
                                      isActive={isTagActive ? true : false}
                                      onClick={() => {
                                          // navigate(`/tag/${tag.id}/${JSON.stringify(tag)}`);
                                          navigate(
                                              `/tag/${tag.id}?sourceType=User&sourceId=${data.id}`
                                          )
                                      }}
                                  />
                              )
                          })}
                </div>
                {data?.attributes?.all_tags?.length >= 15 && (
                    <p className="text-secondary text-sm">
                        <Link
                            to={`/profile/${data.id}?interests`}
                            className="underline"
                        >
                            ...
                        </Link>
                    </p>
                )}
            </div>

            {userData && (
                <div className="h-fit ml-auto  items-center">
                    {handleUnmute && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation()
                                handleUnmute(data)
                            }}
                        >
                            <label
                                style={{
                                    fontSize: 18,
                                    fontWeight: '600',
                                }}
                                className="text-secondary mr-2"
                            >
                                {'Unmute'}
                            </label>
                        </button>
                    )}

                    <button onClick={toggleBookmark} className="h-fit ml-auto">
                        <BookmarkIcon
                            className={cn('h-6 w-6 text-foreground', {
                                'text-primary': isBookmarked,
                            })}
                        />
                    </button>
                </div>
            )}
        </div>
    )
}
