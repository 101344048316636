import apiClient from './client'
export const discover = {
    getDiscover: (query: any) =>
        apiClient.get('reveries/feed', { params: { ...query } }),
    getSearchDiscover: (query: any) =>
        apiClient.get('search/multi_search', { params: { ...query } }),
    getSearchFeed: (query: any) =>
        apiClient.get('reveries/search', { params: { ...query } }),
    getSearchTags: (query: any) =>
        apiClient.get('tags/search', { params: { ...query } }),
    getSearchUsers: (query: any) =>
        apiClient.get('search', { params: { ...query } }),
    getMatch: (id: string) => apiClient.get(`reveries/${id}`),
    getUserReveries: (params: any) =>
        apiClient.get('reveries', { params: { ...params } }),
    getPostResources: (id: string) =>
        apiClient.get(`additional_resources`, {
            params: {
                reverie_id: id,
            },
        }),
    getAttachedResources: (query: any, id: string) =>
        apiClient.get(`reveries/${id}/attached_resources`, {
            params: { ...query },
        }),
    getPostRelated: (id: string) => apiClient.get(`reveries/${id}/related`),
    addPost: (data: any) => apiClient.post('reveries', data),
    updatePost: (id: string, data: any) =>
        apiClient.patch(`reveries/${id}`, data),
    deletePost: (id: string) => apiClient.delete(`reveries/${id}`),
    addReaction: (data: any) => apiClient.post('reactions', data),
    deleteReaction: (id: string) => apiClient.delete(`reactions/${id}`),
    addBookmark: (id: string) =>
        apiClient.post('bookmarks', {
            bookmark: {
                reverie_id: id,
            },
        }),
    deleteBookmark: (id: string) => apiClient.delete(`bookmarks/${id}`),
    getComments: (id: string) =>
        apiClient.get(`reveries/children_feed`, {
            params: {
                id: id,
            },
        }),
    getNewComments: (params: any) =>
        apiClient.get('reveries/all_under_root', {
            params: {
                ...params,
            },
        }),
}
