import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export const removeDupes = (arr: any[], map = new Map()) => {
    arr.forEach((o) => map.set(o.id, o))
    return Array.from(map.values())
}
