import AlertDialog from 'components/AlertDialog'
import { UseAppContext } from 'helpers/contexts/AuthContext'
import { useEffect, useState } from 'react'
import { Button, Collapse } from 'react-bootstrap'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { ReportModel } from './ReportModel'
import { EditModal } from './EditModal'
import { Tooltip } from 'react-tooltip'
import { api } from 'helpers/api'
import apiClient from 'helpers/api/client'
import toast from 'react-hot-toast'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useMuteThread } from 'modules/discover/hooks/useMuteThread'
import { useUnMuteThread } from 'modules/discover/hooks/useUnMuteThread'
import { useIsMutating } from 'react-query'
import { Spinner } from 'components/ui/spinner'

export const MenuOptionTooltip = ({
    sourceType,
    data,
    onDelete = () => {
        window.location.reload()
    },
    onEditSuccess,
    onReply,
    reply = false,
}: {
    sourceType: string
    data: any
    onDelete?: () => void
    onEditSuccess?: (data: any) => void
    onReply?: any
    reply?: boolean
}) => {
    const { user } = UseAppContext()
    const [reportFeed, setReportFeed] = useState(false)
    const [reportUser, setReportUser] = useState(false)
    const [editPost, setEditPost] = useState(false)
    const [open, setOpen] = useState(false)
    const [tooltipVisible, setTooltipVisible] = useState(false)
    const [showDeletePrompt, setShowDeletePrompt] = useState(false)
    const [loading, setLoading] = useState(false)
    const ref = useDetectClickOutside({
        onTriggered: () => setTooltipVisible(false),
    })
    const [isReported, setIsReported] = useState(
        sourceType === 'feed'
            ? data.attributes?.flagged
            : data.attributes?.reported
    )
    const [isMuted, setIsMuted] = useState(
        sourceType === 'user' ? data.attributes?.muted ?? false : false
    )
    const [isThreadMuted, setIsThreadMuted] = useState<boolean>(
        data?.attributes?.muted_thread ?? false
    )

    const handleClose = (type: string) => {
        type === 'feed' ? setReportFeed(false) : setReportUser(false)
    }
    const handleShow = (type: string) => {
        setTooltipVisible(false)
        setOpen(false)
        setTimeout(() => {
            type === 'feed' ? setReportFeed(true) : setReportUser(true)
        }, 200)
    }

    const handleUnreport = () => {
        setLoading(true)
        const url =
            sourceType === 'feed'
                ? `/flag_reveries/${data.id}`
                : `/reported_users/${data.id}`
        apiClient
            .delete(url)
            .then((res) => {
                setLoading(false)
                toast('Unreported successfully')
                setIsReported(false)
                setTooltipVisible(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log({ err })
                toast.error('Something went wrong')
                setTooltipVisible(false)
            })
    }

    const handelBtn = (e: {
        stopPropagation: () => void
        preventDefault: () => void
    }) => {
        e.stopPropagation()
        // OR
        e.preventDefault()
        if (!tooltipVisible && open) {
            setOpen(false)
        }
        setTooltipVisible(!tooltipVisible)
    }

    const handleMuteUser = () => {
        setLoading(true)
        apiClient
            .post('/mute_users', {
                mute_user: {
                    muted_user_id: data.id,
                },
            })
            .then((res) => {
                setLoading(false)
                toast('Muted successfully')
                setIsMuted(true)
            })
            .catch((err) => {
                console.log({ err })
                setLoading(false)
            })
            .finally(() => {
                setTooltipVisible(false)
            })
    }

    const handleUnMuteUser = () => {
        setLoading(true)
        apiClient
            .delete(`/mute_users/${data.id}`)
            .then((res) => {
                setLoading(false)
                toast('Unmuted successfully')
                setIsMuted(false)
            })
            .catch((err) => {
                console.log({ err })
                setLoading(false)
                setTooltipVisible(false)
            })
    }

    useEffect(() => {
        if (!tooltipVisible) {
            setOpen(false)
        }
    }, [tooltipVisible])

    const threadMuteMutation = useMuteThread()
    const threadUnMuteMutation = useUnMuteThread()

    const handleThreadMute = async () => {
        const { muted_thread } = data.attributes
        if (muted_thread) {
            await threadUnMuteMutation.mutateAsync(data?.root?.id || data.id)
        } else {
            await threadMuteMutation.mutateAsync({
                muted_thread: {
                    reverie_id: data?.root?.id || data.id,
                },
            })
        }
        setIsThreadMuted((prevThreadMuted) => !prevThreadMuted)
        // close the menu options
        setTooltipVisible(false)
    }

    const isMutatingThreadMute = useIsMutating({
        mutationKey: ['threadMuteToggle'],
    })

    return (
        <div ref={ref} className="z-11">
            <MoreHorizIcon
                onClick={handelBtn}
                fontSize={'large'}
                // sx={{ color: 'white' }}
                className={`tooltipMain${data.id} text-foreground`}
            />
            <Tooltip
                openOnClick
                hidden={!tooltipVisible}
                clickable
                place={'left'}
                afterHide={() => {
                    setOpen(false)
                    setTooltipVisible(false)
                }}
                anchorSelect={`.tooltipMain${data.id}`}
                className="z-10 tooltiBg opacity-100"
            >
                <div className="w-64 ">
                    <ul>
                        {sourceType === 'feed' &&
                            user?.id.toString() ===
                                data?.attributes?.user_id.toString() && (
                                <Button
                                    onClick={() => {
                                        setTooltipVisible(false)
                                        setOpen(false)
                                        setEditPost(true)
                                    }}
                                    className="w-100 text-white no-underline border-0"
                                >
                                    Edit
                                </Button>
                            )}
                        <Button
                            onClick={() => {
                                if (isReported) {
                                    handleUnreport()
                                } else {
                                    setOpen(!open)
                                }
                            }}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            className="w-100 text-white no-underline border-0"
                        >
                            {isReported ? 'Unreport' : 'Report'}
                        </Button>
                        {sourceType === 'feed' && (
                            <Button
                                disabled={Boolean(isMutatingThreadMute)}
                                onClick={
                                    !isMutatingThreadMute
                                        ? handleThreadMute
                                        : () => {}
                                }
                                className="w-100 text-white no-underline border-0 inline-flex items-center gap-1 justify-center"
                            >
                                {isMutatingThreadMute ? <Spinner /> : <></>}
                                {isThreadMuted
                                    ? 'Unmute Thread'
                                    : 'Mute Thread'}
                            </Button>
                        )}
                        {/* {reply && (
              <Button
                onClick={() => onReply(data)}
                aria-controls="example-collapse-text"
                // aria-expanded={open}
                className="w-100 text-white no-underline border-0"
              >
                Reply
              </Button>
            )} */}
                        <Collapse in={open}>
                            <div id="example-collapse-text">
                                <h6>What do you want to report?</h6>
                                <p>
                                    Your report is anonymous, except if you’re
                                    reporting an intellectual property
                                    infringement. If someone is in immediate
                                    danger, call the local emergency services -
                                    don’t wait.{' '}
                                </p>
                                {sourceType === 'feed' && (
                                    <>
                                        <Button
                                            variant="link"
                                            className="w-100 no-underline border-0 text-white mt-5"
                                            onClick={() => {
                                                handleShow('feed')
                                            }}
                                        >
                                            Report Post, Message or Comment
                                        </Button>
                                    </>
                                )}
                                <Button
                                    variant="link"
                                    onClick={() => {
                                        handleShow('user')
                                    }}
                                    className="w-100 no-underline border-0 text-white mt-2 mb-5"
                                >
                                    Report Account
                                </Button>
                            </div>
                        </Collapse>
                        {sourceType === 'user' && (
                            <Button
                                onClick={() => {
                                    isMuted
                                        ? handleUnMuteUser()
                                        : handleMuteUser()
                                }}
                                className="w-100 no-underline border-0 text-white mt-2 "
                            >
                                {isMuted ? 'Unmute User' : 'Mute User'}
                            </Button>
                        )}
                        {sourceType === 'feed' &&
                            user?.id.toString() ===
                                data?.attributes?.user_id.toString() && (
                                <Button
                                    className="w-100 text-white no-underline border-0"
                                    onClick={() => {
                                        setShowDeletePrompt(true)
                                    }}
                                >
                                    Delete
                                </Button>
                            )}
                    </ul>
                </div>
            </Tooltip>
            {reportFeed && (
                <ReportModel
                    type="feed"
                    mainId={data.id.toString()}
                    show={reportFeed}
                    handleClose={() => handleClose('feed')}
                />
            )}
            {reportUser && (
                <ReportModel
                    type="user"
                    show={reportUser}
                    mainId={`${
                        sourceType === 'feed'
                            ? data?.attributes?.user_id
                            : data.id
                    }`}
                    handleClose={() => handleClose('user')}
                />
            )}
            {editPost && (
                <EditModal
                    type={data?.attributes?.parent ? 'reply' : 'post'}
                    show={editPost}
                    id={data.id}
                    handleClose={() => {
                        setEditPost(false)
                    }}
                    onEditSuccess={onEditSuccess}
                />
            )}
            <AlertDialog
                title="Delete Post"
                message="Are you sure you want to delete this post?"
                open={showDeletePrompt}
                setOpen={setShowDeletePrompt}
                handleConfirm={() => {
                    sourceType === 'feed' && onDelete()
                    api.discover.deletePost(data.id).then((res) => {
                        onDelete()
                    })
                }}
            />
        </div>
    )
}
