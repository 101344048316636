import { Spinner } from 'components/ui/spinner'

export function PrimaryButton({
    children,
    className,
    onClick,
    type,
    isLoading,
    disabled,
    ref,
}: {
    children?: React.ReactNode
    className?: string
    onClick?: () => void
    type?: 'button' | 'submit' | 'reset' | undefined
    isLoading?: boolean
    disabled?: boolean
    ref?: any
}) {
    return (
        <button
            className={`${className} justify-center disabled:bg-[#E52534] bg-[#E52534] hover:bg-[#e52535d5] hover:scale-[1.02] text-white font-[600] w-fit rounded-[8px] transition-all text-[1rem] flex items-center justify-center`}
            type={type}
            onClick={onClick}
            disabled={isLoading || disabled}
            ref={ref}
        >
            {isLoading && <Spinner />}
            {children}
        </button>
    )
}
