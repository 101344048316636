import apiClient from './client'
export const connect = {
    getNewMatches: (query: any) =>
        apiClient.get('friends/matches', { params: { ...query } }),
    getFollowing: (query: any) =>
        apiClient.get('friends/following', { params: { ...query } }),
    getFollowers: (query: any) =>
        apiClient.get('friends/followers', { params: { ...query } }),
    getSearchedUsers: (query: any) =>
        apiClient.get('search', { params: { ...query } }),
}
