import { useLocalStorage } from 'react-use'

export const useTheme = () => {
    const [theme, setTheme] = useLocalStorage<'dark' | 'light'>('theme', 'dark')
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
        theme === 'dark' ||
        (!('theme' in localStorage) &&
            window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
        document.documentElement.classList.add('dark')
    } else {
        document.documentElement.classList.remove('dark')
    }

    return { theme, setTheme }
}
