import { cn } from 'helpers/utils'

export default function Tag({
    title,
    isActive = false,
    customClass,
    onClick,
}: {
    title: string
    isActive?: boolean
    customClass?: string
    onClick?: () => void
}) {
    return (
        <button
            className={`${customClass} px-2 py-1 ${
                isActive ? 'bg-[#292929]' : 'bg-[#e525344d]'
            } rounded-[6px] flex gap-1 items-center`}
            type="button"
            onClick={onClick}
        >
            <span
                className={cn('text-primary font-semibold text-[0.8125rem]', {
                    'dark:text-foreground text-white': isActive,
                })}
            >
                {title}
            </span>
        </button>
    )
}
