import 'bootstrap/dist/css/bootstrap.css'
import './App.css'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { AuthProvider, UseAppContext } from 'helpers/contexts/AuthContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Suspense, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { routes } from 'config/routes'
import { Spinner } from 'components/ui/spinner'
import { useTheme } from 'hooks/useTheme'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 2 * 60000, // 2 minutes
        },
    },
})

function App() {
    useTheme()

    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                    gutter={8}
                    containerClassName=""
                    containerStyle={{}}
                    toastOptions={{
                        // Define default options
                        className: '',
                        duration: 5000,
                        style: {
                            background: '#363636',
                            color: '#fff',
                        },

                        // Default options for specific types
                        success: {
                            duration: 3000,
                            // @ts-ignore
                            theme: {
                                primary: 'green',
                                secondary: 'black',
                            },
                        },
                    }}
                />
                <Suspense
                    fallback={
                        <div className="bg-background h-screen w-full flex items-center justify-center gap-2">
                            <Spinner />
                            <span className="text-white">Loading...</span>
                        </div>
                    }
                >
                    <Router />
                </Suspense>
            </AuthProvider>
        </QueryClientProvider>
    )
}

function Router() {
    const { user, token } = UseAppContext()

    useEffect(() => {
        setTimeout(() => {}, 500)
    }, [user, token])

    const sentryCreateBrowserRouter =
        Sentry.wrapCreateBrowserRouter(createBrowserRouter)

    return (
        <>
            <RouterProvider
                router={sentryCreateBrowserRouter(
                    routes.map((route) => ({
                        ...route,
                        element: route.requiresAuth ? (
                            user && token ? (
                                route.element
                            ) : (
                                <Navigate to="/signup" />
                            )
                        ) : (
                            route.element
                        ),
                    }))
                )}
            />
        </>
    )
}

export default Sentry.withProfiler(App)
