// Update the path to your notificationSlice
import apiClient from 'helpers/api/client'
import { SetStateAction, useEffect, useState } from 'react'
import {
    setSelectedTags,
    setSerendipityBoost,
    setUserTags,
    setUserTagsLoading,
} from 'redux/slices/userTagsSlice'
import { useAppDispatch, useAppSelector } from './useRedux'
import { UseAppContext } from 'helpers/contexts/AuthContext'

export const useUserTagsHook = (forceRefresh = false) => {
    const dispatch = useAppDispatch()
    const userTagsState = useAppSelector((state) => state.userTags)
    const [coreUserTags, setCoreUserTags] = useState<any[]>([])
    const { user, token } = UseAppContext()

    const fetchTagsFromAPI = () => {
        if (userTagsState.loading) return
        dispatch(setUserTagsLoading(true))
        apiClient
            .get('tags/serendipity_tags', {
                params: {
                    serendipity_level: 0,
                },
            })
            .then((res: any) => {
                dispatch(setUserTags(res.data.data))
                setUserTagsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                dispatch(setUserTagsLoading(false))
            })
    }

    const upsertTags = (tags: string[]) => {
        // Upsert the tags
        dispatch(setUserTagsLoading(true))
        apiClient
            .post('/user_tags/bulk_upsert', {
                tag_names: tags,
            })
            .then((res: any) => {
                dispatch(setUserTagsLoading(false))
                dispatch(setUserTags(res.data.data))
            })
            .catch((err) => {
                dispatch(setUserTagsLoading(false))
                console.log(err)
            })
    }

    const addTag = (
        tagInput: { name: any },
        inspired_by_type: string | null = null,
        inspired_by_id: string | null = null,
        obj: any = null
    ) => {
        dispatch(setUserTagsLoading(true))
        let params: any = {
            tag: {
                name: tagInput.name,
            },
        }
        console.log({ inspired_by_type, inspired_by_id })
        if (inspired_by_id) {
            params = {
                ...params,
                inspired_by_type: inspired_by_type,
                inspired_by_id: inspired_by_id,
            }
        }
        obj &&
            dispatch(
                setUserTags([
                    {
                        ...obj,
                        attributes: {
                            ...obj.attributes,
                            name: obj.name,
                            serendipity_level: 0,
                        },
                        type: 'tags',
                    },
                    ...userTagsState.userTags,
                ])
            )

        apiClient
            .post('tags', params)
            .then((res: any) => {
                // remove duplicate tags
                // const uniqueTags = [res.data.data, ...userTagsState.userTags].filter(
                //   (tag) => tag.name !== res.data.data.name
                // );
                !obj &&
                    dispatch(
                        setUserTags([res.data.data, ...userTagsState.userTags])
                    )
                dispatch(setUserTagsLoading(false))
            })
            .catch((err) => {
                console.log(err)
                dispatch(setUserTagsLoading(false))
                //setError(err.response.data.error || "Couldn't create tag");
            })
    }

    const removeTagServerSide = (id: string) => {
        dispatch(setUserTagsLoading(true))
        apiClient
            .delete('user_tags/' + id)
            .then((res: any) => {
                console.log('tag removed')
                dispatch(setUserTagsLoading(false))
            })
            .catch((err) => {
                console.log(err)
                dispatch(setUserTagsLoading(false))
                //setError(err.response.data.error || "Couldn't create tag");
            })
    }

    const removeTag = (id: string) => {
        removeTagServerSide(id)
        dispatch(
            setUserTags(
                userTagsState.userTags.filter(
                    (t: { id: any }) => t.id.toString() !== id.toString()
                )
            )
        )
        setCoreUserTags(
            coreUserTags.filter((t: any) => t.toString() !== id.toString())
        )
    }

    const getTagsAtSerendipityLevel = (level: number) => {
        const tagsArray: { id: any; name: any; serendipity_level: any }[] = []
        if (level === 0) {
            userTagsState.userTags.forEach((tag: any, index) => {
                tagsArray.push({
                    id: tag.id,
                    name: tag.attributes?.name,
                    serendipity_level: tag.attributes?.serendipity_level,
                })
            })
            return tagsArray
        }

        userTagsState.userTags.forEach(
            (tag: { attributes: { children: any[] } }, index: any) => {
                // Go through children
                tag.attributes?.children?.forEach(
                    (
                        childTag: {
                            serendipity_level: string
                            id: any
                            name: any
                        },
                        childIndex: any
                    ) => {
                        if (parseInt(childTag.serendipity_level) == level) {
                            tagsArray.push({
                                id: childTag.id,
                                name: childTag.name,
                                serendipity_level: childTag.serendipity_level,
                            })
                        }
                    }
                )
            }
        )
        return tagsArray
    }

    useEffect(() => {
        // Get the set of core tags for marking up the reveries
        if (userTagsState.userTags.length !== 0) {
            const tagIdArray: SetStateAction<any[]> = []
            userTagsState.userTags.forEach((tag) => {
                tagIdArray.push(tag.id.toString())
            })
            setCoreUserTags(tagIdArray)
        }
    }, [userTagsState.userTags])

    useEffect(() => {
        if (
            user &&
            token &&
            (userTagsState.userTags.length === 0 || forceRefresh)
        )
            fetchTagsFromAPI()
    }, [])

    useEffect(() => {
        if (userTagsState.isAllTagsSelected) {
            userTagsState.selectedTags.length > 0 &&
                dispatch(setSelectedTags([]))
            userTagsState.serendipityBoost &&
                dispatch(setSerendipityBoost(false))
        }
    }, [userTagsState.isAllTagsSelected])

    useEffect(() => {
        userTagsState.serendipityBoost &&
            userTagsState.selectedTags.length > 0 &&
            dispatch(setSelectedTags([]))
    }, [userTagsState.serendipityBoost])

    return {
        userTagsState,
        fetchTagsFromAPI,
        removeTag,
        upsertTags,
        addTag,
        getTagsAtSerendipityLevel,
        coreUserTags,
    }
}
