import Navbar from 'components/NavBar'
import { checkIsMobile } from 'helpers/display'

export default function BlackLayout({
    children,
    className,
    noChildrenPadding = false,
}: {
    children: React.ReactNode
    className?: string
    noChildrenPadding?: boolean
}) {
    const isMobile = checkIsMobile()

    return (
        <div
            className={`${className} bg-background h-screen w-full flex justify-center`}
        >
            <div
                className={
                    isMobile
                        ? 'max-w-[1382px] w-full flex'
                        : 'max-w-[1382px] px-4 w-full flex'
                }
            >
                <Navbar />
                <div
                    className={`${
                        noChildrenPadding ? 'p-0' : 'p-4'
                    } overflow-y-auto flex-1`}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}
