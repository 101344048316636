import BlackLayout from 'layouts/BlackLayout'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { InfiniteData, useInfiniteQuery, useIsMutating } from 'react-query'
import { api } from 'helpers/api'
import { Spinner } from 'components/ui/spinner'
import { styled } from 'styled-components'
// import SearchIcon from 'assets/search.svg'
import defaultUser from 'assets/user.svg'
import { ReactComponent as BookmarkIcon } from 'assets/bookmark.svg'
import { ReactComponent as SearchIcon } from 'assets/search.svg'
import { ReactComponent as SettingIcon } from 'assets/setting.svg'
// import CloseIcon from "assets/close.svg";
import { ReactComponent as CloseIcon } from 'assets/closeWColor.svg'
import Tag from 'components/Tag'
import moment from 'moment'
import { UseAppContext } from 'helpers/contexts/AuthContext'

// import { AddTag, RemoveTag } from "helpers/hooks/tagManager";
import { useAppDispatch } from 'redux/reduxHooks/useRedux'
import {
    setAllTagsSelected,
    setSelectedTags,
    setSerendipityBoost,
} from 'redux/slices/userTagsSlice'
import { useUserTagsHook } from 'redux/reduxHooks/useUserTagsHooks'
import TagSelf from 'components/TagSelf'
import { useDebounce } from 'helpers/hooks/useDebounce'
import { ReactComponent as LogoPlain } from '../../assets/ic_logo_plain.svg'
import NotificationIcon from 'modules/notification/components/NotificationIcon'
// import SettingIcon from 'assets/setting.svg'
import toast from 'react-hot-toast'
import { ReactComponent as ShareIcon } from 'assets/share.svg'
// import { Tooltip } from 'react-tooltip'
import { ReactComponent as MCLogo } from 'assets/ic_logo_plain.svg'
import { MenuOptionTooltip } from 'components/Models/MenuOptions'
import { EnterNameModal } from './EnterNameModal'
import { SafeTextDisplay } from 'helpers/display'
import DownloadBanner from './DownloadBanner'
import { Button } from 'components/ui/button'
import { useToggle } from 'react-use'
import { SerendipitySettingsDialog } from './components/SerendipitySettingsDialog'
import { PersonCard } from 'modules/connect/Connect'
import { ReactComponent as MavenLogo } from 'assets/logo.svg'
import { IoInformationCircleOutline } from 'react-icons/io5'
import apiClient from 'helpers/api/client'
import { useMuteThread } from './hooks/useMuteThread'
import { useUnMuteThread } from './hooks/useUnMuteThread'
import { PrimaryButton } from 'components/Button'
import { cn } from 'helpers/utils'
import {
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
    Tooltip,
} from 'components/ui/tooltip'

interface DiscoverHeaderProps {
    searchText: string
    setSearchText: (text: string) => void
    setSearchDataState: (data: any) => void
}
const DiscoverHeader = ({
    searchText,
    setSearchText,
    setSearchDataState,
}: DiscoverHeaderProps) => {
    return (
        <div className="flex justify-between flex-wrap items-center mt-4 mb-4">
            <div className="flex">
                <MavenLogo
                    className={'transition-all text-foreground'}
                    // color="#fff"
                    width={22}
                    height={28}
                />
                <h4 className="ml-2 text-[1.5rem] text-foreground font-bold">
                    Maven
                </h4>
            </div>
            <div className="flex flex-wrap items-center">
                <div className="grid gap-2">
                    <div className="flex overflow-y-auto gap-2 items-center w-full mr-3"></div>
                </div>
                <div className="mr-4 d-inline-flex"></div>
                <div className="relative">
                    {searchText.length > 0 && (
                        <CloseIcon
                            height={22}
                            onClick={() => {
                                setSearchText('')
                                setSearchDataState(null)
                            }}
                            width={22}
                            className="absolute top-1/2 transform -translate-y-1/2 right-0 dark:text-[#C7C7CC] text-secondary-foreground"
                        />
                    )}

                    <input
                        type="text"
                        placeholder="Search Maven"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="bg-transparent px-4 w-[12.5rem] py-1 text-secondary-foreground placeholder-secondary-foreground text-sm focus-visible:outline-none border-b border-b-[#262626]"
                    />
                    <SearchIcon className="absolute top-1/2 transform -translate-y-1/2 left-0" />
                </div>

                <div className="text-foreground">
                    <NotificationIcon />
                </div>
            </div>
        </div>
    )
}

interface SearchedTagsProps {
    tags: any
    searchText: string
}
const SearchedTags = ({ tags, searchText }: SearchedTagsProps) => {
    const navigate = useNavigate()
    const { user, token } = UseAppContext()
    return (
        <>
            <h4 className="text-xl text-foreground font-bold flex mb-2">
                Interests
            </h4>
            {tags?.data.length > 0 &&
                tags?.data.map((item: any) => {
                    return (
                        <Link className="w-full mb-4" to={`/tag/${item.id}`}>
                            <TagCard
                                item={item}
                                includeFollows={user != null && token != null}
                            />
                        </Link>
                    )
                })}
            {tags?.data.length == 0 && (
                <div className="flex flex-row gap-2">
                    <label className="text-foreground">
                        No interests found
                    </label>
                </div>
            )}
            {tags?.data.length === 3 && (
                <div className="flex flex-row gap-2">
                    <button
                        type="button"
                        onClick={() => {
                            navigate(`/searchtags?searchText=${searchText}`)
                        }}
                    >
                        <label className="text-foreground">
                            Find more interests...
                        </label>
                    </button>
                </div>
            )}
        </>
    )
}

export default function Discover() {
    const { ref, inView } = useInView()
    const discoverRef = useRef<HTMLDivElement>(null)
    const dispatch = useAppDispatch()
    const { userTagsState, getTagsAtSerendipityLevel } = useUserTagsHook()
    const [tags, setTags] = useState<any[]>([])
    const [nameModalOpen, setNameModalOpen] = useState(false)
    const { user, token } = UseAppContext()
    const navigate = useNavigate()
    const [dataState, setDataState] = useState<InfiniteData<any> | undefined>()
    const [showSerendipityDialog, toggleSerendiptiyDialog] = useToggle(false)
    const [searchText, setSearchText] = useState<string>('')
    const debouncedSearchText = useDebounce(searchText, 500)
    const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false)
    const [searchDataState, setSearchDataState] = useState<any>(null)
    const searchLimit = 3

    const getKeyByType = () => {
        if (userTagsState.isAllTagsSelected) {
            return 'discoverAllTags'
        } else {
            return 'discoverBoostOff'
        }
    }

    // Fetches from a paginated API with param `page`
    const fetchData = async ({
        pageParam = {
            page: '1',
            //limit: "20",
            group_page_by_thread: true,
            mix_in_serendipity: true,
        },
    }) => {
        let newParams: {
            page: string
            //limit: string;
            serendipity_level?: string
            show_all_posts?: boolean
            group_page_by_thread?: boolean
            mix_in_serendipity?: boolean
            tag_ids?: any[]
        } = {
            ...pageParam,
        }
        if (userTagsState.serendipityBoost) {
            newParams.serendipity_level = '2'
        }
        if (userTagsState.isAllTagsSelected) {
            newParams.show_all_posts = true
        }

        if (userTagsState.selectedTags?.length > 0) {
            const tags = userTagsState.selectedTags?.map((item: any) => item.id)
            newParams.tag_ids = tags
        }

        const res = await api.discover.getDiscover(newParams)
        return res.data
    }

    // Fetches from a paginated API with param `page`
    const fetchSearchData = async () => {
        setSearchIsLoading(true)
        let newParams: {
            limit: string
            query: string
        } = {
            limit: searchLimit.toString(),
            query: debouncedSearchText,
        }
        const res = await api.discover.getSearchDiscover(newParams)
        console.log(res.data)
        setSearchIsLoading(false)
        setSearchDataState(res.data)
    }

    const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isLoading } =
        useInfiniteQuery([getKeyByType()], fetchData, {
            enabled: userTagsState.userTags.length !== 0,
            getNextPageParam: (lastPage) => {
                if (lastPage.meta.current_page < lastPage.meta.total_pages) {
                    let params: {
                        page: string
                        //limit: number;
                        serendipity_level?: string
                        show_all_posts?: boolean
                        group_page_by_thread?: boolean
                        mix_in_serendipity?: boolean
                        tag_ids?: any[]
                    } = {
                        page: lastPage.meta.next_page,
                        //limit: 20,
                        group_page_by_thread: true,
                        mix_in_serendipity: true,
                    }
                    if (userTagsState.serendipityBoost) {
                        params.serendipity_level = '2'
                    }
                    if (userTagsState.isAllTagsSelected) {
                        params.show_all_posts = true
                    }

                    if (userTagsState.selectedTags?.length > 0) {
                        params.tag_ids = userTagsState.selectedTags?.map(
                            (item: any) => item.id
                        )
                    }

                    return (
                        // @ts-ignore
                        lastPage.meta.next_page && params
                    )
                }
            },
        })

    const source = dataState

    const isLoadingSource = isLoading
    const isFetchingNextPageSource = isFetchingNextPage
    const hasNextPageSource = hasNextPage
    const fetchNextPageSource = fetchNextPage

    useEffect(() => {
        if (debouncedSearchText.trim().length > 0) {
            fetchSearchData()
        } else {
            setSearchDataState(null)
        }
    }, [debouncedSearchText])

    useEffect(() => {
        setSelectedTags([])
        const tagsAtSerendipityLevel = getTagsAtSerendipityLevel(
            userTagsState.serendipityBoost ? 2 : 0
        )
        setTags(tagsAtSerendipityLevel)
    }, [userTagsState.serendipityBoost])

    useEffect(() => {
        if (userTagsState.userTags.length !== 0) {
            const tagsAtSerendipityLevel = getTagsAtSerendipityLevel(
                userTagsState.serendipityBoost ? 2 : 0
            )
            setTags(tagsAtSerendipityLevel)
        }
    }, [userTagsState.userTags])

    useEffect(() => {
        if (inView) {
            fetchNextPageSource()
        }
    }, [inView])

    useEffect(
        () => {
            if (data) {
                setDataState(data)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    )

    useEffect(() => {
        if (
            (user && token && !user?.attributes?.name) ||
            user?.attributes?.name === ''
        ) {
            setNameModalOpen(true)
        }
    }, [user])

    const onLoad = () => {
        const scrollPosition = localStorage.getItem('scrollPosition')
        if (scrollPosition) {
            discoverRef.current?.scrollTo({
                top: parseInt(scrollPosition),
                behavior: 'auto',
            })
        }
    }

    const onScroll = () => {
        localStorage.setItem(
            'scrollPosition',
            discoverRef.current?.scrollTop
                ? discoverRef.current?.scrollTop?.toString()
                : '0'
        )
    }

    if (isLoadingSource && dataState === undefined) {
        return (
            <BlackLayout className="text-center">
                <Spinner />
            </BlackLayout>
        )
    }

    return (
        <BlackLayout noChildrenPadding>
            <DownloadBanner />
            <div
                className="h-screen overflow-y-auto scrollbar-hide flex"
                ref={discoverRef}
                onScroll={onScroll}
                onLoad={onLoad}
            >
                <div className="flex flex-col max-w-[1200px] px-8">
                    <DiscoverHeader
                        searchText={searchText}
                        setSearchText={setSearchText}
                        setSearchDataState={setSearchDataState}
                    />
                    {isLoadingSource ||
                        (searchIsLoading && (
                            // make the div centered

                            <div className="text-center">
                                <Spinner />
                            </div>
                        ))}

                    <div className="flex md:flex-row flex-col-reverse gap-8">
                        {searchText.length > 0 ? (
                            <div className="flex flex-col flex-auto bg-background w-full">
                                {searchDataState?.tags && (
                                    <SearchedTags
                                        searchText={searchText}
                                        tags={searchDataState?.tags}
                                    />
                                )}
                                {searchDataState?.reveries && (
                                    <div className="pt-8">
                                        <h4 className="text-xl text-foreground font-bold flex mb-2">
                                            Posts
                                        </h4>
                                        {searchDataState?.reveries?.data
                                            .length > 0 &&
                                            searchDataState?.reveries?.data.map(
                                                (item: any) => {
                                                    return (
                                                        <TopicsCardV2
                                                            key={item.id}
                                                            title={
                                                                item.attributes
                                                                    .title
                                                            }
                                                            body={
                                                                item.attributes
                                                                    .body
                                                            }
                                                            user={{
                                                                id: item
                                                                    .attributes
                                                                    .user.id,
                                                                name: item
                                                                    .attributes
                                                                    .user.name,
                                                                avatar: item
                                                                    .attributes
                                                                    .user
                                                                    .avatar,
                                                            }}
                                                            root={
                                                                item.attributes
                                                                    .root
                                                            }
                                                            rootRepliesCount={
                                                                item.attributes
                                                                    .root_replies_count
                                                            }
                                                            topRepliesUsers={
                                                                item.attributes
                                                                    .top_replies_users
                                                            }
                                                            heroImageUrl={
                                                                item.attributes
                                                                    .hero_image
                                                                    .original
                                                            }
                                                            id={item.id}
                                                            updatedAt={
                                                                item.attributes
                                                                    .created_at
                                                            }
                                                            reactions={
                                                                item.attributes
                                                                    .reactions
                                                            }
                                                            bookmarked={
                                                                item.attributes
                                                                    .bookmarked
                                                            }
                                                            tags={
                                                                item.attributes
                                                                    .tags
                                                            }
                                                            partOfSequence={
                                                                false
                                                            }
                                                            lastInSequence={
                                                                true
                                                            }
                                                            itemInSequence={0}
                                                            totalNumberInSquence={
                                                                item.attributes
                                                                    .total_number_in_sequence
                                                            }
                                                        />
                                                    )
                                                }
                                            )}
                                        {searchDataState?.reveries?.data
                                            .length == 0 && (
                                            <div className="flex flex-row gap-2">
                                                <label className="text-foreground">
                                                    No posts found
                                                </label>
                                            </div>
                                        )}
                                        {searchDataState?.reveries?.data
                                            .length === 3 && (
                                            <div className="flex flex-row gap-2">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        navigate(
                                                            `/searchfeed?searchText=${searchText}`
                                                        )
                                                    }}
                                                >
                                                    <label className="text-foreground">
                                                        Find more posts...
                                                    </label>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {searchDataState?.users && (
                                    <div className="pt-8">
                                        <h4 className="text-xl text-foreground font-bold flex mb-2">
                                            Users
                                        </h4>
                                        <div className="grid gap-4 px-2 py-3">
                                            {searchDataState?.users?.data
                                                .length > 0 &&
                                                searchDataState?.users?.data.map(
                                                    (item: any) => {
                                                        return (
                                                            <PersonCard
                                                                data={item}
                                                            />
                                                        )
                                                    }
                                                )}
                                        </div>
                                        {searchDataState?.users?.data.length ==
                                            0 && (
                                            <div className="flex flex-row gap-2">
                                                <label className="text-foreground">
                                                    No users found
                                                </label>
                                            </div>
                                        )}
                                        {searchDataState?.users?.data.length ===
                                            3 && (
                                            <div className="flex flex-row gap-2">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        navigate(
                                                            `/searchusers?searchText=${searchText}`
                                                        )
                                                    }}
                                                >
                                                    <label className="text-foreground">
                                                        Find more users...
                                                    </label>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="flex-auto">
                                {source?.pages?.map((page: any) => {
                                    return page?.data?.map(
                                        (item: any, index: number) => {
                                            return (
                                                <TopicsCardV2
                                                    key={index}
                                                    title={
                                                        item.attributes.title
                                                    }
                                                    body={item.attributes.body}
                                                    user={{
                                                        id: item.attributes.user
                                                            .id,
                                                        name: item.attributes
                                                            .user.name,
                                                        avatar: item.attributes
                                                            .user.avatar,
                                                    }}
                                                    root={item.attributes.root}
                                                    rootRepliesCount={
                                                        item.attributes
                                                            .root_replies_count
                                                    }
                                                    topRepliesUsers={
                                                        item.attributes
                                                            .top_replies_users
                                                    }
                                                    heroImageUrl={
                                                        item.attributes
                                                            .hero_image.original
                                                    }
                                                    id={item.id}
                                                    item={item}
                                                    updatedAt={
                                                        item.attributes
                                                            .created_at
                                                    }
                                                    reactions={
                                                        item.attributes
                                                            .reactions
                                                    }
                                                    bookmarked={
                                                        item.attributes
                                                            .bookmarked
                                                    }
                                                    tags={item.attributes.tags}
                                                    partOfSequence={
                                                        item.attributes
                                                            .total_number_in_sequence &&
                                                        item.attributes
                                                            .total_number_in_sequence >
                                                            1
                                                    }
                                                    lastInSequence={
                                                        item.attributes
                                                            .item_number_in_sequence +
                                                            1 ===
                                                        item.attributes
                                                            .total_number_in_sequence
                                                    }
                                                    itemInSequence={
                                                        item.attributes
                                                            .item_number_in_sequence
                                                    }
                                                    totalNumberInSquence={
                                                        item.attributes
                                                            .total_number_in_sequence
                                                    }
                                                    mcMet={
                                                        item.attributes
                                                            .minimal_criteria_passed
                                                    }
                                                    serendipitous={
                                                        item.attributes
                                                            .serendipitous
                                                    }
                                                    showInfo={true}
                                                />
                                            )
                                        }
                                    )
                                })}
                            </div>
                        )}
                        {searchText.length == 0 && (
                            <div className="flex flex-col items-start gap-2 py-4">
                                {user && token && (
                                    <Link
                                        to={`/interests`}
                                        className="items-center"
                                    >
                                        <div className="flex flex-row">
                                            <h6 className="text-xs mr-2 text-left self-center text-foreground">
                                                Interest Settings
                                            </h6>
                                            {/* <img
                                                src={SettingIcon}
                                                alt="home"
                                                className="w-[1.5rem] h-[1.5rem]"
                                                loading="lazy"
                                            /> */}
                                            <SettingIcon className="w-[1.5rem] h-[1.5rem] [&>path]:stroke-foreground" />
                                        </div>
                                    </Link>
                                )}
                                {user && token && (
                                    <Button
                                        onClick={toggleSerendiptiyDialog}
                                        variant={'ghost'}
                                        className="px-0 gap-2 hover:bg-transparent text-foreground hover:text-foreground text-xs"
                                    >
                                        Serendipity Settings
                                        <LogoPlain height={24} width={24} />
                                    </Button>
                                )}
                                {user && token && user.attributes.is_admin && (
                                    <TagSelf
                                        title="All Posts"
                                        customClass="min-w-fit"
                                        // @ts-ignore
                                        isActive={
                                            userTagsState.isAllTagsSelected
                                        }
                                        onClick={() => {
                                            setSearchText('')
                                            setSearchDataState(null)
                                            dispatch(setSerendipityBoost(false))
                                            dispatch(
                                                setAllTagsSelected(
                                                    !userTagsState.isAllTagsSelected
                                                )
                                            )
                                        }}
                                    />
                                )}
                                {tags.length > 0 &&
                                    tags.map((item, index) => {
                                        const isTagActive =
                                            userTagsState.selectedTags?.find(
                                                (tag: any) =>
                                                    tag.name === item.name
                                            )
                                        return (
                                            <TagSelf
                                                title={item.name}
                                                customClass="min-w-fit"
                                                key={index}
                                                // @ts-ignore
                                                isActive={isTagActive}
                                                onClick={() => {
                                                    setSearchText('')
                                                    setSearchDataState(null)
                                                    navigate(`/tag/${item.id}`)
                                                }}
                                            />
                                        )
                                    })}

                                {tags.length == 0 && user && token && (
                                    <Link to={`/interests`}>
                                        <h6 className="text-foreground">
                                            Personalize your feed:
                                        </h6>
                                        <h6 className="text-foreground mt-4">
                                            Follow interests by tapping here or
                                            on
                                        </h6>
                                        <div className="mt-4">
                                            <Tag
                                                title={'any'}
                                                customClass="min-w-fit"
                                                key={0}
                                                // @ts-ignore
                                                isActive={false}
                                            />
                                            <div className="mt-2">
                                                <Tag
                                                    title={'interest'}
                                                    customClass="min-w-fit"
                                                    key={0}
                                                    // @ts-ignore
                                                    isActive={false}
                                                />
                                            </div>
                                            <div className="mt-2">
                                                <Tag
                                                    title={'tag'}
                                                    customClass="min-w-fit"
                                                    key={0}
                                                    // @ts-ignore
                                                    isActive={false}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="text-center p-4">
                        <button
                            ref={ref}
                            onClick={() => fetchNextPageSource()}
                            disabled={
                                !hasNextPageSource || isFetchingNextPageSource
                            }
                            className="text-foreground"
                        >
                            {isFetchingNextPageSource ? (
                                <Spinner />
                            ) : hasNextPageSource ? (
                                'Load Newer'
                            ) : (
                                ''
                            )}
                        </button>
                    </div>
                </div>
            </div>
            <EnterNameModal
                show={nameModalOpen}
                handleClose={() => setNameModalOpen(false)}
            />
            <SerendipitySettingsDialog
                open={showSerendipityDialog}
                onOpenChange={toggleSerendiptiyDialog}
            />
        </BlackLayout>
    )
}

export function TagCard({
    item,
    includeFollows,
}: {
    item: any
    includeFollows: boolean
}) {
    const { userTagsState, addTag, removeTag } = useUserTagsHook()
    const isTagActive = userTagsState.userTags?.find(
        (tag: any) => tag.name === item?.attributes?.name
    )

    return (
        <div className="flex flex-row gap-2" key={item.id}>
            <div className="basis-1/4 flex flex-col items-center">
                {item?.attributes?.hero_image?.original && (
                    <img
                        src={item?.attributes?.hero_image?.original}
                        alt="profile"
                        className="w-8 h-8 rounded-full mr-2"
                        loading="lazy"
                    />
                )}
                <h4 className="text-base text-foreground font-bold flex text-center">
                    {item?.attributes?.name}
                </h4>
            </div>
            <div className="basis-1/2 px-5">
                <p className="text-[13px] text-secondary-foreground mt-1  max-w-[1000px]">
                    {item?.attributes?.description?.description}
                </p>
            </div>
            {includeFollows && (
                <div className="basis-1/4 flex items-center">
                    <Tag
                        title={isTagActive ? 'Unfollow' : 'Follow'}
                        customClass="min-w-fit"
                        key={item?.id.toString()}
                        // @ts-ignore
                        isActive={isTagActive}
                        onClick={() => {
                            // navigate(`/tag/${data.id}/${sourceType}/${sourceId}`);

                            if (!isTagActive) {
                                addTag(
                                    {
                                        name: item?.attributes?.name,
                                    },
                                    null,
                                    null,
                                    {
                                        name: item?.attributes?.name,
                                        id: item?.id,
                                    }
                                )
                                // addTag(item.name);
                                // refetchUserData();
                            } else {
                                removeTag(item?.id.toString())
                                // refetchUserData();
                            }
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export function TopicsCardV2({
    title,
    body,
    user,
    root,
    rootRepliesCount,
    topRepliesUsers,
    heroImageUrl,
    id,
    updatedAt,
    reactions,
    bookmarked,
    tags,
    partOfSequence,
    lastInSequence,
    itemInSequence,
    totalNumberInSquence,
    tagName,
    mcMet,
    item = null,
    serendipitous = false,
    showInfo = false,
}: {
    title: string
    body: string
    user: {
        id: string
        avatar: {
            thumbnail: string
        }
        name: string
    }
    root: any
    rootRepliesCount: number
    topRepliesUsers: any[]
    heroImageUrl: string
    id: string
    updatedAt: string
    reactions?: {
        user_id: number
    }[]
    bookmarked?: boolean
    tags?: {
        id: number
        name: string
    }[]
    partOfSequence?: boolean
    lastInSequence?: boolean
    itemInSequence?: number
    totalNumberInSquence?: number
    tagName?: string
    mcMet?: boolean
    item?: any
    serendipitous?: boolean
    showInfo?: boolean
}) {
    const [isPostBookmarked, setIsPostBookmarked] = useState<boolean>(false)
    const { user: userData, refetchUserData, token } = UseAppContext()
    const [isThreadMuted, setIsThreadMuted] = useState<boolean>(
        root ? root?.muted_thread : item?.attributes?.muted_thread ?? false
    )

    const [reasonInFeed, setReasonInFeed] = useState('')

    const { userTagsState } = useUserTagsHook()
    const navigate = useNavigate()

    const isRoot = !root // I realize this looks confusing but not having a root means you are the root :)
    const isRootMuted = root?.attributes?.muted_thread ?? false
    let remainingReplies = 0

    if (partOfSequence) {
        remainingReplies = rootRepliesCount - (totalNumberInSquence || 0)
    } else {
        if (isRoot) {
            remainingReplies = rootRepliesCount
        } else {
            remainingReplies = rootRepliesCount - 1
        }
    }

    const lastMessage =
        (remainingReplies >= 0 && !isRoot) ||
        (partOfSequence && !lastInSequence)

    const threadMuteMutation = useMuteThread()
    const threadUnMuteMutation = useUnMuteThread()
    const handleThreadMute = async (e: any) => {
        e?.stopPropagation()
        console.log('handleThreadMute called')
        const { muted_thread } = root ? root : item.attributes
        const mainId = root ? root?.id : item.id
        if (muted_thread) {
            await threadUnMuteMutation.mutateAsync(mainId)
        } else {
            await threadMuteMutation.mutateAsync({
                muted_thread: {
                    reverie_id: mainId,
                },
            })
        }
        setIsThreadMuted((prevThreadMuted) => !prevThreadMuted)
        // close the menu options
    }

    const isMutatingThreadMute = useIsMutating({
        mutationKey: ['threadMuteToggle'],
    })

    const handleBookmark = () => {
        // @ts-ignore
        if (!isPostBookmarked) {
            api.discover
                // @ts-ignore
                .addBookmark(id)
                .then(() => {
                    setIsPostBookmarked(true)
                })
                .catch(() => {
                    // This has an error it sends error 404 after the delete request so I just set the state to false
                    setIsPostBookmarked(true)
                })
        }
        // @ts-ignore
        else {
            api.discover
                // @ts-ignore
                .deleteBookmark(id)
                .then(() => {
                    setIsPostBookmarked(false)
                })
                .catch(() => {
                    // This has an error it sends error 404 after the delete request so I just set the state to false
                    setIsPostBookmarked(false)
                })
        }
    }

    const loadReasonInFeed = () => {
        apiClient
            .get(`reveries/${id}/why_in_feed`)
            .then((res) => {
                setReasonInFeed(res?.data?.data?.reason)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        // @ts-ignore
        // if (reactions?.length > 0) {
        // find the user's reaction and set the state isPostLiked to true
        // const userReaction = reactions?.find(
        //   (reaction: any) => reaction.user_id === 1
        // );
        // if (userReaction) {
        //   setIsPostLiked(true);
        // }

        // find the user's bookmark and set the state isPostBookmarked to true
        // @ts-ignore
        setIsPostBookmarked(bookmarked)
        // }
    }, [bookmarked])

    const handleShareTap = () => {
        navigator.clipboard.writeText(
            `${window.location.origin.toString()}/discover/${id}`
        )
        toast.success('Copied to clipboard')
    }

    const _replySummary = (replyCount: number) => {
        const otherRepliesMessage = !isRoot ? ' other' : ''

        return (
            <Link
                to={`/discover/${root?.id || id}`}
                className="flex items-center cursor-pointer"
            >
                <div className="flex items-center gap-1">
                    <div className="flex -space-x-3">
                        {topRepliesUsers.slice(0, 3).map((user, index) => {
                            return (
                                <img
                                    className="w-6 h-6 rounded-full bg-black"
                                    key={index}
                                    src={
                                        user?.avatar?.thumbnail === 'user.png'
                                            ? defaultUser
                                            : user?.avatar?.thumbnail
                                    }
                                    alt={user?.name}
                                />
                            )
                        })}
                    </div>
                    <p className="text-secondary-foreground">
                        {replyCount}
                        {replyCount == 1
                            ? `${otherRepliesMessage} reply`
                            : `${otherRepliesMessage} replies`}
                    </p>
                </div>
                <div className="flex items-center gap-1">
                    {/* <img
                        src={
                            topRepliesUsers[0]?.avatar?.thumbnail === 'user.png'
                                ? defaultUser
                                : topRepliesUsers[0]?.avatar?.thumbnail
                        }
                        className="w-6 h-6 rounded-full bg-black"
                    />
                    {topRepliesUsers[1] && (
                        <div className="ml-[-16px]">
                            <img
                                src={
                                    topRepliesUsers[1]?.avatar?.thumbnail ===
                                    'user.png'
                                        ? defaultUser
                                        : topRepliesUsers[1]?.avatar?.thumbnail
                                }
                                className="w-6 h-6 rounded-full bg-black"
                            />
                        </div>
                    )}
                    {topRepliesUsers[2] && (
                        <div className="ml-[-16px]">
                            <img
                                src={
                                    topRepliesUsers[2]?.avatar?.thumbnail ===
                                    'user.png'
                                        ? defaultUser
                                        : topRepliesUsers[2]?.avatar?.thumbnail
                                }
                                className="w-6 h-6 rounded-full bg-black"
                            />
                        </div>
                    )} */}
                    {/* <p className="text-secondary-foreground">
                        {replyCount}{' '}
                        {replyCount == 1
                            ? `${otherRepliesMessage} reply`
                            : `${otherRepliesMessage} replies`}
                    </p> */}
                </div>
            </Link>
        )
    }

    const _showInfoTooltip = () => {
        return (
            <>
                {serendipitous ? (
                    <>
                        {/* <a
                            className="w-5 h-5"
                            data-tooltip-id="mc-tooltip"
                            data-tooltip-content="Maven choose this for you in an effort to keep your feed open to serendipity."
                            data-tooltip-place="top"
                        >
                            <CustomMCLogo color="#e52534" />
                        </a>
                        <Tooltip id="mc-tooltip" /> */}
                        <TooltipProvider delayDuration={100}>
                            <Tooltip>
                                <TooltipTrigger className="w-5 h-5">
                                    <CustomMCLogo color="#e52534" />
                                </TooltipTrigger>
                                <TooltipContent>
                                    <label className="text-foreground">
                                        Maven choose this for you in an effort
                                        to keep your feed open to serendipity.
                                    </label>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </>
                ) : (
                    <>
                        <TooltipProvider delayDuration={100}>
                            <Tooltip
                                onOpenChange={(isOpen) => {
                                    if (isOpen) {
                                        loadReasonInFeed()
                                    }
                                }}
                            >
                                <TooltipTrigger className="w-5 h-5">
                                    <IoInformationCircleOutline
                                        size={'1.3em'}
                                        className="text-foreground"
                                    />
                                </TooltipTrigger>
                                <TooltipContent>
                                    <label className="text-foreground">
                                        {reasonInFeed}
                                    </label>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </>
                )}
            </>
        )
    }

    const handelBtn = (e: {
        stopPropagation: () => void
        preventDefault: () => void
    }) => {
        e.stopPropagation()
        // OR
        e.preventDefault()
    }

    let outerDivClasses = 'w-full p-2 mb-2 rounded-lg !border-[#292929] border'

    // if (lastInSequence || !partOfSequence) {
    //     outerDivClasses +=
    //         'border-b-solid border-b-[1px] border-b-[#363636] pb-4'
    // }

    // if (itemInSequence == 0 || !partOfSequence) {
    //     outerDivClasses += ' pt-4'
    // }

    return isThreadMuted || isRootMuted ? (
        <div className="my-2 flex flex-row items-center justify-between w-full border-b-solid border-b-[1px] border-b-[#363636] pb-4">
            <label className="text-white">This thread is muted</label>
            <PrimaryButton
                type="button"
                onClick={() => handleThreadMute(null)}
                className="text-white px-2 py-1  mr-4 bg-[#363636]"
            >
                Unmute
            </PrimaryButton>
        </div>
    ) : (
        <div className={outerDivClasses}>
            {root && itemInSequence == 0 && (
                <>
                    <Link
                        to={`/discover/${root?.id}`}
                        className="flex gap-2 flex-col cursor-pointer"
                    >
                        <div className="flex flex-row justify-between items-center">
                            <Link
                                to={`/profile/${root?.user.id}`}
                                className="flex gap-2 items-center cursor-pointer"
                            >
                                <img
                                    src={
                                        root?.user.avatar.thumbnail ===
                                        'user.png'
                                            ? defaultUser
                                            : root?.user.avatar.thumbnail
                                    }
                                    alt="profile"
                                    className="w-6 h-6 rounded-full bg-black"
                                    loading="lazy"
                                />
                                <h6 className="text-secondary-foreground font-bold text-sm">
                                    {root?.user.name}
                                </h6>
                            </Link>
                            <button
                                type="button"
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => handleThreadMute(e)}
                            >
                                <CloseIcon
                                    height={25}
                                    width={25}
                                    // color="#C7C7CC"
                                    className="dark:text-[#C7C7CC] text-secondary-foreground"
                                />
                            </button>
                        </div>

                        <div className="flex">
                            <div className="flex justify-between gap-4 w-full">
                                <div className="flex flex-col w-full">
                                    <h2 className="text-foreground font-bold mb-2">
                                        {root?.title}
                                    </h2>
                                    {root?.body && (
                                        <div
                                            className={cn(
                                                'text-secondary-foreground mb-2'
                                            )}
                                        >
                                            <SafeTextDisplay
                                                text={root?.body}
                                                truncate={200}
                                            />
                                        </div>
                                    )}

                                    <div className="flex flex-wrap gap-2">
                                        {root?.tags?.map(
                                            (
                                                item: {
                                                    id: number
                                                    name: string
                                                },
                                                index: number
                                            ) => {
                                                const isTagActive =
                                                    userTagsState.userTags.find(
                                                        (tag: {
                                                            id: number
                                                            name: string
                                                        }) =>
                                                            tag?.name ===
                                                            item.name
                                                    )
                                                return (
                                                    <Link
                                                        to={`/tag/${item.id}?sourceType=Reverie&sourceId=${id}`}
                                                    >
                                                        <Tag
                                                            title={item.name}
                                                            customClass="min-w-fit"
                                                            key={index}
                                                            // @ts-ignore
                                                            isActive={
                                                                isTagActive
                                                            }
                                                        />
                                                    </Link>
                                                )
                                            }
                                        )}
                                    </div>
                                </div>

                                {root?.hero_image.original && (
                                    <img
                                        src={root?.hero_image.original}
                                        alt="profile"
                                        className="w-auto h-24 mb-4 rounded-[1.25rem] object-contain bg-background self-center mr-0"
                                        loading="lazy"
                                    />
                                )}
                            </div>
                        </div>
                    </Link>
                </>
            )}

            {lastMessage && (
                <h2 className="text-secondary-foreground font-[600] mt-4">
                    Latest Reply:
                </h2>
            )}

            <div
                className={`flex flex-col w-[100%-6] ${lastMessage ? 'ml-6' : ''}`}
            >
                {itemInSequence != 0 && (
                    <div className="ml-2 w-0.5 bg-gray-500 h-8"></div>
                )}
                <div className="flex  flex-row justify-between w-full">
                    <Link
                        to={`/profile/${user.id}`}
                        className="flex gap-2 items-center cursor-pointer"
                    >
                        <img
                            src={
                                user.avatar.thumbnail === 'user.png'
                                    ? defaultUser
                                    : user.avatar.thumbnail
                            }
                            alt="profile"
                            className="w-6 h-6 rounded-full bg-black"
                            loading="lazy"
                        />
                        <h6 className="text-secondary-foreground font-bold text-sm">
                            {user.name}
                        </h6>
                    </Link>
                    <div className="flex flex-row items-center">
                        {showInfo && (
                            <div className="mr-2 mb-0">
                                {_showInfoTooltip()}
                            </div>
                        )}

                        <h6 className="font-bold text-xs mr-2 text-secondary-foreground">
                            {moment(updatedAt).fromNow()}
                        </h6>
                        {item && user && token && (
                            // display cross icon for delete
                            <div className="flex flex-row items-center">
                                {(!item?.attributes?.parent || isRoot) && (
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => handleThreadMute(e)}
                                    >
                                        <CloseIcon
                                            height={25}
                                            width={25}
                                            // color="#C7C7CC"
                                            className="dark:text-[#C7C7CC] text-secondary-foreground"
                                        />
                                    </div>
                                )}

                                <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={handelBtn}
                                >
                                    <MenuOptionTooltip
                                        sourceType={'feed'}
                                        data={item}
                                        onEditSuccess={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex flex-row mr-4">
                    <div className="flex flex-col w-full">
                        <Link
                            to={`/discover/${id}`}
                            className="w-full flex flex-wrap-container justify-between gap-4"
                        >
                            <div className="gap-1">
                                {!root && (
                                    <h2 className="text-foreground font-[700] my-2">
                                        {title}
                                    </h2>
                                )}
                                <div className="flex flex-row">
                                    <div
                                        className={cn(
                                            'text-secondary-foreground mb-2'
                                        )}
                                    >
                                        <SafeTextDisplay
                                            text={body}
                                            truncate={400}
                                        />
                                    </div>
                                </div>
                            </div>

                            {!root && heroImageUrl && (
                                <img
                                    src={heroImageUrl}
                                    alt="profile"
                                    className="w-auto h-[12.5rem] w-[12.5rem] mb-4 rounded-[1.25rem] object-contain bg-[#161616] self-center ml-auto mr-0"
                                    loading="lazy"
                                />
                            )}
                        </Link>
                        <div className="flex gap-8 relative">
                            <div className="flex flex-wrap gap-2">
                                {tags?.map((item, index) => {
                                    const isTagActive =
                                        userTagsState.userTags.find(
                                            (tag: any) =>
                                                tag.attributes?.name ===
                                                item.name
                                        )
                                    return (
                                        <Link
                                            to={`/tag/${item.id}?sourceType=Reverie&sourceId=${id}`}
                                        >
                                            <Tag
                                                title={item.name}
                                                customClass="min-w-fit"
                                                key={index}
                                                // @ts-ignore
                                                isActive={isTagActive}
                                            />
                                        </Link>
                                    )
                                })}
                            </div>
                            <div className="flex gap-4 items-center ml-auto">
                                <button
                                    className="ml-auto h-fit"
                                    onClick={handleShareTap}
                                >
                                    <ShareIcon className="w-[1.2rem] h-[1.2rem] [&>path]:stroke-foreground stroke-2" />
                                </button>
                                {user && token && (
                                    <button onClick={handleBookmark}>
                                        <BookmarkIcon
                                            className={cn(
                                                'transition-all duration-200 ease-in-out stroke-foreground hover:stroke-primary',
                                                {
                                                    'stroke-primary':
                                                        isPostBookmarked,
                                                }
                                            )}
                                        />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {remainingReplies > 0 && lastInSequence && (
                <div className="flex items-left justify-between pt-4 flex-col">
                    {_replySummary(remainingReplies)}
                </div>
            )}
        </div>
    )
}

const CustomMCLogo = styled(MCLogo)`
    stroke: #e52534;
    fill: #e52534;
`
